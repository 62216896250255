@import "../variables";

.bp4-tabs.bp4-vertical {
  .bp4-tab-indicator-wrapper,
  .bp4-tab {
    &:focus {
      outline: none;
      outline-offset: 0;
    }
  }

  .bp4-tab-list .bp4-tab {
    font-size: $normalSpacing;
    line-height: 20px;
    color: $mainBlackText;
    height: 38px;
    border-radius: $standardBorderRadius;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
}

.bp4-tabs.bp4-vertical {
  .bp4-tab:hover {
    color: $mainGrayText2;
  }
}
