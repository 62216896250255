.application-comments-button {
  display: flex;
  justify-content: center;
  align-items: center;

  .unread-comment-count {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
    width: 20px;
    height: 20px;
    color: white;
    border-radius: 50%;
    text-align: center;
    font-size: 1rem;
    margin-left: 9rem;
    position: absolute;
    margin-bottom: 2rem;
  }
}
