@import "../../../variables";

@keyframes enterGrid {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  99% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.e-grid.fortified-grid {
  height: auto;
  border-radius: $cardBorderRadius;
  border: 0px;
  box-shadow: $cardBoxShadow;
  padding: 0px 15px;
  background: $white;

  /* duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
  animation: 600ms ease-out 0s 1 enterGrid;

  .e-gridheader {
    border-radius: $cardBorderRadius;
    border-width: 0px;
  }

  .e-gridcontent {
    border-radius: $cardBorderRadius;
    .e-table {
      border-bottom: 1px solid $gridBorderColor;
    }
    .e-content {
      height: calc(100vh - 300px) !important;
    }
  }

  .e-headercontent {
    border-right-width: 0px !important;
    border-bottom-width: 1px;
  }

  .base-grid-icon {
    color: $mainGrayText1;
  }

  .icon-grid-column {
    display: flex;
    align-items: center;
    justify-content: center;

    .active-grid-icon {
      color: $mainBlueText1;
    }

    :hover {
      cursor: pointer;
      color: $mainBlueText1;
    }

    .grid-icon-value {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: red;
      width: 18px;
      height: 18px;
      color: white;
      border-radius: 50%;
      text-align: center;
      margin-left: -1.6rem;
      position: absolute;
      margin-top: -0.8rem;
    }
  }

  .icon-grid-comment-column {
    justify-content: left; // prevents icon from being pushed out of view by centered text preview of CommentsGridColumn.
  }

  .certificate-grid-column:hover {
    cursor: pointer;
    span {
      color: $mainBlueText1;
    }
  }
}

.board-grid-container:has(+ .board-metrics-container
    .grid-metrics.grid-metrics-expanded)
  .e-grid.fortified-grid
  .e-gridcontent
  .e-content {
  height: calc(100vh - 388px) !important;
}

.board-grid-container:has(+ .board-metrics-container
    .grid-metrics.grid-metrics-empty)
  .e-grid.fortified-grid
  .e-gridcontent
  .e-content {
  height: calc(100vh - 306px) !important;
}

.board-grid-container.has-tabs:has(+ .board-metrics-container
    .grid-metrics.grid-metrics-expanded)
  .e-grid.fortified-grid
  .e-gridcontent
  .e-content {
  height: calc(100vh - 372px) !important;
}

.e-grid .e-row.e-altrow {
  background-color: $midBorderColor;
}

.e-headertext {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.reopen-tooltip {
  max-width: 24rem;
  padding: 0.5rem;
}

.grid-metrics .bp4-collapse-body:has(> .ucl-grid) {
  max-height: 208px;
  overflow-y: auto;
}

.grid-metrics {
  .ucl-card__body {
    min-height: 0px;
    .ucl-subcard {
      border-bottom: none !important;
    }
    .grid-metrics__picker-button-container {
      display: flex;
      align-items: center;

      border-bottom: none !important;

      button.e-progress-btn {
        width: 25px;
        height: 25px;
        padding: 0 0 0 1px;

        display: flex;
        align-items: center;
      }
    }
    .ucl-card__body {
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-start !important;
      flex-wrap: wrap;
      .bp4-form-group {
        white-space: nowrap;
      }
    }
  }
}

.grid-metrics.grid-metrics-expanded .ucl-card__body .ucl-card__body {
  height: 132px;
}

.board-grid-container.has-tabs
  ~ .board-metrics-container
  .grid-metrics.grid-metrics-expanded
  .ucl-card__body
  .ucl-card__body {
  height: 66px;
}
