@import "../../../variables";

// fortified-grid-views-picker
.fortified-grid-views-picker {
  .fortified-grid-views-picker-config {
    display: inline-flex;
    button.bp4-button.bp4-button {
      color: $mainGray6;
      transition: min-width 1s ease-in-out;
      min-width: 202px;
      justify-content: flex-start;
      align-items: flex-end;
      max-height: 35px;

      .bp4-button-text {
        width: 100%;
        > span {
          width: 100%;
          display: inline-block;
        }
      }
      &:hover {
        color: $mainBlackText;
      }
    }
  }

  &.ready {
    .fortified-grid-views-picker-config {
      button.bp4-button.bp4-button {
        min-width: 100px;
        position: relative;
        top: 3px;
      }
    }
  }
}

// fortified-grid-views-picker-dialog
.fortified-grid-views-picker-dialog {
  .bp4-dialog {
    width: 800px;
    height: 570px;
  }
}

// fortified-grid-view-configuration-search
.fortified-grid-view-configuration-search {
  padding: 0 5px;
  margin: 0px 5px;

  > label {
    display: block;
    margin-bottom: 10px;
  }

  .e-input-group.e-control-wrapper.e-ddl {
    border-bottom: 1px solid transparent;
    // v Better than the overwrite needed
    border-color: transparent !important;
  }

  #fortified-grid-view-configuration-search-list {
    @include inputBase;
    border-radius: 3px;
    padding: 0 10px;
    height: 34px;
    line-height: 34px;
    box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
      inset 0 0 0 1px rgba(16, 22, 26, 0.15),
      inset 0 1px 1px rgba(16, 22, 26, 0.2);

    &:after {
      border: 0;
    }
  }
}

//fortified-grid-view-configuration
.fortified-grid-view-configuration {
  position: relative;
  height: 100%;

  .fortified-grid-view-configuration-columns {
    margin: 10px 5px;

    > label {
      display: block;
      font-weight: bold;
      font-size: 14px;
      margin-right: 19px;
      padding: 10px 5px;
      border-bottom: 1px solid $gridBorderColor;
    }
  }
}
#fortified-grid-view-configuration-columns-list {
  max-height: 300px;
  padding-right: 10px;

  &.e-listview:not(.e-list-template) .e-list-item {
    border-bottom: 1px solid $gridBorderColor;
    padding: 0 5px;
    color: $mainGray6;

    &.e-hover {
      background: $white2;
    }

    .view-column {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button.bp4-button.bp4-button.bp4-minimal.view-column-remove {
        background: none;
        border-radius: 50%;
        width: 16px;
        height: 16px;

        &:hover {
          background: $red3;
        }
      }
    }
  }
}

// fortified-grid-view-configuration-footer
.fortified-grid-view-configuration-footer {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: -15px;
  right: 0;

  button.bp4-button.bp4-button,
  button.bp4-button.bp4-button.bp4-minimal {
    color: $mainBlackText;
    min-width: 100px;
    margin-bottom: 1rem;
  }
  button.bp4-button.bp4-intent-primary {
    color: $white;
  }
}
