.informational-card-container {
  margin: 24px;

  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0.02em;

  .ucl-card,
  .bp4-card {
    border-radius: 6px;
    box-shadow: none;
    background-color: #d4e3ff;
  }

  .ucl-card-header {
    border-bottom: none;
    padding: 8px 16px 8px 4px;
    min-height: 0;

    color: #334056;
    font-weight: 600;

    .informational-card-icon {
      color: #4d85eb;
      margin-right: 8px;
    }
  }

  .ucl-card__body {
    min-height: 100px;
  }

  .informational-card-body {
    display: flex;
    flex-direction: column;
    padding: 8px 28px 0 28px;

    color: #334056;
    line-height: 20px;
  }
}
