@import "../../../../../variables";

.complex-evaluation-buildings-card {
  .ucl-card {
    &__header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .flex-break {
        flex-basis: 100%;
        height: 0;
      }

      .primary-card-description {
        margin-top: 1.5em;
        margin-bottom: 0.5em;
      }
    }
  }
  .relativity-subcard-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
  }

  .status-heading {
    margin-left: auto;
  }

  .buildings-loading-spinner {
    display: flex;
    justify-content: center;
  }

  .fc-fmf-payment-button-container {
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
}
