.ucl-dialog.e-dialog {
  @media screen and (max-width: 1000px) {
    width: 80% !important;
    height: 100%;

    .ucl-footer {
      height: 15vh !important;
      .ucl-footer-children {
        display: flex;
        flex-direction: column;
        padding: 0;
        button {
          margin: 0;
        }

        .wildfire-new-grid-comment-form__submit-button {
          margin-top: 5px;
        }
      }
    }
  }
}
