.ucl-dialog-container.home-create-evaluation-dialog {
  //Used to Combat existing Fortified Header.
  //Once Converted this style should be removed
  .e-dlg-container {
    top: -64px;

    .create-home-evalaution-dialog {
      padding-bottom: 2rem;
      .create-home-evaluation-info-container {
        .redesignation-dialog-evaluation-values {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          column-gap: 10px;
          padding-bottom: 1rem;
        }

        .redesignation-dialog-evaluation-values > div {
          display: contents;
        }
      }
    }
  }
}
