@import "../../../variables";

.wildfire-iteration-engine__plus-info-message.e-message.e-filled.e-info {
  background-color: #447a99;
  border-color: #497087;
}
.wildfire-applicant-iteration-engine {
  .wildfire-iteration-engine {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      justify-content: space-between;
      button {
        height: 40px;
        min-height: 40px;
        max-height: 40px;
        font-size: 16px;
        justify-content: center;
        min-width: 150px;
      }

      .left-panel {
        display: flex;
        align-items: center;
        justify-self: flex-start;
      }

      .right_panel {
        display: flex;
        justify-self: flex-end;
      }
    }

    &__content {
      width: 100%;
      padding: 10px;

      .wildfire-iteration-engine__tab {
        display: flex;
        gap: 10px;
        align-items: center;

        .wildfire-iteration-engine__tab-title {
          font-weight: 500;
        }
        .bp4-icon > svg:not([fill]) {
          fill: #ef5353;
        }

        .bp4-text-overflow-ellipsis {
          color: #ef5353;
        }
      }

      .change-requests-count {
        display: flex;
        align-items: center;

        .bp4-tag.bp4-round {
          min-height: 10px;
          min-width: 10px;

          color: white;
          background-color: #ffc6c68f;

          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
        }
      }

      .e-tab .e-tab-header .e-toolbar-items {
        width: 100%;
      }
      .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
        border-radius: 8px 8px 0px 0px;
        border: 1px solid #ddd;
        border-bottom: none;
        background: #eee;
        padding: 0 15px;
        padding-top: 5px;
        height: 50px;
        opacity: 0.8;

        &:hover::before {
          opacity: 1;
        }
      }
      .e-tab
        .e-tab-header:not(.e-vertical)
        .e-toolbar-item.e-active
        .e-tab-wrap {
        background: #fff;
      }

      .e-toolbar
        .e-toolbar-items.e-toolbar-multirow
        .e-toolbar-item:not(.e-separator) {
        margin: 0;
        margin-right: 1px;
        height: 50px;
        min-height: 50px;
      }

      .e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
        border: none;
      }
      .e-tab .e-content {
        overflow: auto;
        min-height: calc(100vh - 200px);
        background: #fff;
        border: 1px solid #ddd;
        border-top: none;

        .wildfire-applicant-iteration-engine-change-requests {
          margin-bottom: 100px;
          .app-wie-change-requests {
            &.app-wie-change-requests-no-requests {
              display: flex;
              justify-content: center;
              margin-top: 80px;
              font-weight: 400;
              font-size: 16px;
              color: #aaa;
            }
            > ul {
              margin: 0;
              padding: 0;
            }

            li.app-wie-change-request-details {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #ddd;
              padding: 5px 30px;
              min-height: 50px;

              &:hover {
                background-color: #f8f8f8;
              }

              .app-wie-change-request-details-status {
                border-radius: 16px;
                opacity: 0.9;

                .app-wie-change-request-details-status-icon {
                  display: none;
                }

                &:hover {
                  opacity: 1;
                }

                &.app-wie-change-request-details-status-1 {
                  background-color: #f8cd00;
                  color: #333;
                }
                &.app-wie-change-request-details-status-2 {
                  background-color: #72a1bd;
                  color: #fff;
                }
              }
            }

            &.app-wie-change-requests-resolved {
              .app-wie-change-requests-resolved-banner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #d1f4d0;
                color: #333;
                height: 50px;
                align-content: center;
                padding: 15px;
                margin-top: 30px;

                path {
                  fill: #5aad58;
                }
                > span {
                  margin-left: 10px;
                  font-weight: 500;
                }

                &__text {
                  > span {
                    margin-left: 10px;
                  }
                }
                &__buttons {
                  .relativity-secondary-button {
                    animation: fade-in 0.3s ease-in-out;
                    .e-btn-content {
                      svg {
                        margin-left: 10px;
                      }
                    }
                  }
                }
              }

              .app-wie-change-request-details-action {
                path {
                  fill: #5aad58;
                }
              }
            }
          }
        }
      }
    }
  }
}

.label-danger .ucl-label-title {
  background-color: $red1;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.ucl-dialog.e-dlg-container {
  .e-dialog.app-wie-change-request-details-dialog {
    width: 80% !important;
    height: 90% !important;
    .e-dlg-content {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      overflow: hidden;
    }

    .ucl-footer {
      border-top: 1px solid #eee !important;
    }
  }
}

@media screen and (max-width: 1275px) {
  .ucl-dialog.e-dlg-container {
    .e-dialog.app-wie-change-request-details-dialog {
      .e-dlg-content {
        overflow: auto;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .wildfire-applicant-iteration-engine {
    padding-top: 10px;

    .wildfire-iteration-engine {
      &__content {
        .e-items.e-toolbar-items.e-toolbar-multirow {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .wildfire-iteration-engine__tab {
          .wildfire-iteration-engine__tab-title-prefix {
            display: none;
          }
        }

        .change-requests-count {
          display: flex;
          align-items: center;

          .bp4-icon {
            display: none;
          }
          .change-requests-count-suffix {
            display: none;
          }
        }

        .e-tab .e-content {
          .wildfire-applicant-iteration-engine-change-requests {
            .app-wie-change-requests {
              li.app-wie-change-request-details {
                display: flex;
                flex-direction: column;

                .app-wie-change-request-details-action {
                  padding-top: 10px;
                  padding-bottom: 10px;
                }

                .app-wie-change-request-details-status {
                  min-width: 0;
                }
              }
            }

            .app-wie-change-requests.app-wie-change-requests-resolved
              .app-wie-change-requests-resolved-banner {
              flex-direction: column;
              height: 85px;

              &__buttons {
                padding-top: 5px;
              }
            }
          }
        }
        .e-toolbar {
          .e-toolbar-items.e-toolbar-multirow
            .e-toolbar-item:not(.e-separator) {
            width: 45%;
          }
        }
      }
    }
  }

  .wildfire-applicant-iteration-engine .wildfire-iteration-engine__header {
    .left-panel {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 10px;

      .relativity-secondary-button {
        width: 100%;
      }
      .application-comments-button {
        width: 100%;
      }
      button {
        width: 100%;
        margin: 0 0 10px 0;
      }
    }

    .right-panel {
      display: none;
    }
    width: 100%;
  }

  .ucl-dialog.e-dlg-container {
    .e-dialog.app-wie-change-request-details-dialog {
      width: 100% !important;
      height: 100% !important;
      max-height: 100%;
      border-radius: 0px;

      .e-dlg-content {
        overflow: auto;
      }
    }

    .wildfire-box-content-explorer-field {
      width: 90vw;
    }
  }

  .ucl-dialog.e-dlg-container .e-popup.e-popup-open.e-dialog .ucl-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #eee !important;
    max-height: 70px;

    .ucl-footer-children,
    .relativity-primary-button {
      display: flex;
      justify-content: center;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
