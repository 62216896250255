.image-annotation-editor-footer {
  padding-top: 20px;
}

.image-annotation-editor {
  height: 100%;

  .e-image-upload {
    display: none;
  }

  .FIE_tabs {
    display: none;
  }

  .FIE_image-tool-button {
    display: none;
  }

  .FIE_root {
    flex-direction: column-reverse;
  }

  .FIE_topbar {
    flex-direction: row-reverse;
  }

  .IwuPi {
    border-bottom: unset;
  }
}
