.ucl-dialog.e-dialog {
  border-radius: 6px;

  .e-dlg-header-content {
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid #e6ecf0;
    padding: 12px 24px;

    .e-dlg-header {
      margin-top: 8px;
      font-family: Lato;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .e-dlg-content {
    border-radius: 0 0 6px 6px;
    padding-bottom: 64px;

    .wildfire-dialog-body-text {
      font-family: Lato;
      font-size: 16px;
      font-weight: 400;
      line-height: 40px;
      letter-spacing: 0.01em;
      color: #1e1e1e;
      margin-bottom: 16px;
    }

    .ucl-footer {
      border-top: none;
      box-shadow: none;
    }
  }
}
