.eligibility-banner-link-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .bp4-button.eligibility-banner-link-button {
    align-self: center;

    color: #ffffff;
    background-color: #4d85eb;

    font-family: Lato;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;

    .bp4-icon {
      color: inherit;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;

    .bp4-button.eligibility-banner-link-button:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
