.wildfire-form-container {
  margin-bottom: 30px;

  form.field-form {
    .ucl-base-input-numeric-field.e-numerictextbox.e-input {
      width: 20%;
      min-width: 200px;
    }
  }

  .wildfire-form-footer {
    display: flex;
    justify-content: flex-end;

    position: fixed;
    bottom: 0;
    background: #fff;
    width: 99%;
    padding: 15px 20px 10px 15px;
    box-shadow: -2px -6px 9px -4px #ededed;
    margin-left: -30px;

    button.bp4-button {
      border: none;
      box-shadow: none;
    }

    button.bp4-button.wildfire-form-back-button {
      width: 80px;
      height: 34px;
      margin-right: auto;
    }

    button.bp4-button.wildfire-form-save-button {
      width: 64px;

      color: #4dd45a;
      background-color: #cdf4d0;
    }

    button.bp4-button.wildfire-form-navigation-button {
      color: #4d85eb;
      background-color: #d4e3ff;

      .bp4-icon {
        padding-top: 2px;
        color: inherit;
      }
    }

    button.bp4-button.wildfire-form-submit-button {
      color: #ffffff;
      background-color: #4dd45a;

      .bp4-icon {
        color: inherit;
      }
    }

    button.bp4-button.button-desatuarated {
      color: #afb7c4;
      background-color: #e6ecf0;
    }
  }
}

.evaluation-forms-container {
  display: none;
  &.evaluation-forms-container-initialized {
    display: block;
  }
}
