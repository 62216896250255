.wildfire-submit-button {
  .e-progress-btn.e-info {
    background-color: #4dd45a;
    font-size: 16px;
    color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s ease, box-shadow 0.3s ease,
      transform 0.3s ease;

    .e-btn-content {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        padding-top: 2px;
        padding-left: 10px;
      }
    }

    &:hover {
      background-color: #45bf51; /* Slightly darker shade of green */
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      transform: translateY(-2px);
    }
  }

  .e-progress-btn.e-info:disabled {
    background-color: #9e9e9e;
    color: #ffffff;
    opacity: 0.5;
    cursor: not-allowed;
    border: 1px solid #bdbdbd;
  }

  .e-progress-btn.e-info:disabled:hover {
    animation: pulse 1s infinite;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  @media screen and (max-width: 900px) {
    margin-top: 10px;
  }
}
