@import "../../../../variables";

.wildfire-grid-comments-dialog {
  width: 100%;
  .e-dlg-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20rem !important;
  }

  .wildfire-existing-grid-comments-view {
    &__comment-card {
      margin-bottom: 1rem;
      .comment-card-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;

        .comment-card-actions {
          display: flex;
          align-items: center;

          .comment-card-viewer-name {
            font-size: small;
          }
        }
      }

      .comment-card-date {
        font-size: small;
        color: $mainGrayText1;
      }

      .comment-card-view-button {
        span {
          font-size: small;
        }
      }

      textarea.bp4-input {
        height: 6rem;
        white-space: wrap;
        overflow-y: auto;
        text-wrap: wrap;
      }
    }
  }

  .wildfire-new-grid-comment-form {
    &__content {
      position: fixed;
      bottom: 0;
      left: 0;
      height: 17rem;
      background-color: #fff;
      margin: 1rem;
      width: calc(100% - 27px);
      overflow-y: auto;

      .ucl-base-input-text-area-field-container {
        textarea.bp4-input {
          height: 6rem;
          white-space: wrap;
          overflow-y: auto;
          text-wrap: wrap;
        }
      }

      .disclaimer {
        margin: 8px 0;
        color: $mainGrayText1;
        text-align: left;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .wildfire-existing-grid-comments-view {
    padding-bottom: 5rem;
  }

  .wildfire-new-grid-comment-form {
    &__content {
      height: 24rem !important;
    }
  }
}
