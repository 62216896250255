.wildfire-unsaved-changes-leave-button {
  font-size: 16px;
  .e-btn-content {
    color: #e52a49;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.wildfire-unsaved-changes-stay-button {
  font-size: 16px;
  .e-btn-content {
    color: #26cf8b;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

button.e-control.e-progress-btn.e-lib.e-info.wildfire-unsaved-changes-leave-button.e-btn.e-spin-left:disabled {
  background-color: #cccccc;
  color: #666666;
  border: 1px solid #999999;
  cursor: not-allowed;
  opacity: 0.6;
}
