@import "../../../variables";

.error-message {
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  -webkit-transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  opacity: 1;
  margin-top: 0;
  padding-top: 5px;
  color: $red1;
}
