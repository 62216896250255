.wildfire-app-header {
  display: flex;
  flex-direction: row;

  &__primary {
    padding-right: 5px;
  }

  &__address {
    display: flex;
    flex-direction: row;
  }

  &__application-id {
    padding-left: 5px;
  }

  @media screen and (max-width: 1000px) {
    &__primary {
      display: none;
    }
    &__address {
      &__secondary {
        display: none;
      }
    }

    &__application-id {
      display: none;
    }
  }
}
