.create-wildfire-application-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;

  button.bp4-button.bp4-minimal.create-wildfire-application-button {
    border: 2px dashed lightblue;
    width: 80%;
    height: 20rem;
    background-color: #dfe3fe;
  }
}
