$white: #ffffff;
$white2: #f2f5f8;
$mainBackground: #f5f5f5;

$sidebarBlue: #4489c5;
$sidebarGray: #515e75;

$mainBlueText1: #3c85f3;
$mainBlueText2: #4d85eb;
$blue3: rgba(77, 133, 235, 0.2);

$mainGrayText1: rgba(6, 34, 65, 0.5);
$mainGrayText2: #7f90a2;
$mainGrayText3: #7b90a4;
$mainGrayText4: #afb7c4;
$mainGray5: #f8fafc;
$mainGray6: #505e70;
$mainGray7: #dadde3;

$mainBlackText: #0d223f;

$green1: hsl(156, 69%, 48%);
$green2: rgba(38, 205, 137, 0.2);
$green3: rgba(40, 205, 137, 0.15);
$green4: hsl(145, 70%, 75%);

$orange1: #fea500;
$orange2: rgba(255, 189, 99, 0.2);
$orange3: #fea5009e;

$red1: hsl(350, 78%, 53%);
$red2: hsl(350, 78%, 88%);
$red3: #f8c9d066;

$backgroundHighlight: rgba(13, 34, 63, 0.05);

$tagGreenBackground: $green2;
$tagGreenText: $green1;

$tagRedBackground: rgba(232, 65, 93, 0.2);
$tagRedText: $red1;

$tooltipBackground: rgba(14, 22, 36, 0.85);
$tooltipMainText: $white;
$tooltipHeaderText: $mainGrayText4;

// Spacings
$normalSpacing: 16px;
$mediumSpacing: 20px;
$extraLargeSpacing: $normalSpacing * 2;
$largeSpacing: 32px;
$standardBorderRadius: 6px;

$standardFontSize: 16px;

$iterationEngineTabsWidth: 75%;
$iterationEngineCommentWidth: calc(100% - #{($iterationEngineTabsWidth)});

$iterationEngineTabListWidth: 26%;
$iterationEngineFormContainerWidth: calc(
  100% - #{$iterationEngineTabListWidth}
);

// Border and Shadows
$cardBoxShadow: 0px 0px 4px 2px #e5e5e5ab;
$cardBorderRadius: 4px;
$pillBoxShadow: 0px 0px 1px 1px #e1dfdfab;
$pillBoxShadowHover: 0px 0px 1px 1px $orange3;
$relativitySubCardBottomBorder: 1px solid #11141826;

// Grid
$gridBorderColor: #e0e0e0;
$midBorderColor: #e0e0e07a;

// Input
@mixin inputBase {
  background-color: $mainGray5;
  box-shadow: none;
  border-radius: $standardBorderRadius;
}
