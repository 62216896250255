@import "../../../variables";

.evaluation-container {
  display: flex;
  flex: 1 1 0;
  overflow: hidden;
  margin: 10px;

  &__status {
    margin-right: 10px;
    max-width: 350px;
    overflow-y: scroll;
    height: calc(100vh - 80px);

    &__disabled-tabs.bp4-tabs.bp4-vertical {
      .bp4-icon {
        margin: 0 10px;
      }

      .bp4-icon-tick-circle {
        color: $green1;
      }

      .bp4-icon-circle {
        color: #ffbd63;
      }

      .bp4-icon-full-circle {
        color: $mainGray7;
      }

      .bp4-tab {
        cursor: auto;
      }

      .bp4-tab:hover {
        color: unset;
      }

      > .bp4-tab-list .bp4-tab[aria-selected="true"] {
        background-color: $mainBackground !important;
      }
    }

    &__view-certificate-button {
      margin-left: 43px !important;
    }

    &__actions {
      display: flex;
      margin: 10px 0;
      justify-content: center;
    }

    &__divider {
      margin-bottom: 10px;
    }

    .evaluation-add-building-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 1rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    overflow: hidden;
    height: 96%;
    &__heading {
      display: flex;
      padding: 10px 10px 10px 0;
      flex-direction: row-reverse;
      align-items: center;

      &__label {
        display: flex;
        flex: 1 1 0;

        .bp4-tag {
          padding: 5px 16px;
          font-weight: 600;
        }
        .bp4-tag.bp4-intent-primary {
          background-color: $blue3;
          color: $mainBlueText2;
        }
      }
      &__actions {
        display: flex;
        justify-content: flex-end;
      }
    }

    .bp4-card {
      overflow: auto;
      margin: 5px;
    }

    .evaluation-container__external-card {
      margin-bottom: 2rem;
    }

    &__evaluation-form {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      overflow: hidden;

      .bp4-heading {
        padding: 10px 0;
        border-bottom: 1px solid #e6ecf0;
      }
    }
  }
}

.evaluation-history,
.evaluation-relationships {
  .field-label {
    margin-bottom: 5px;
    padding-left: 10px;
    .label-title {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .history-list,
  .relationship-list {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    a {
      margin-bottom: 5px;
    }
  }
}

.evaluation-expiration-container {
  background-color: #eaebec;
  padding: 0.5rem;
  margin-top: 2rem;
  border-radius: 5%;
  &__field {
    display: flex;
    flex-direction: row;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }

  &__label {
    padding-right: 2rem;
    font-weight: bold;
  }
}
