@import "../../../variables";

.page-sidebar {
  background-color: $white;
  width: 60px;
  height: 100%;
  min-width: 60px;
  box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);

  .page-sidebar-item {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    color: $sidebarGray;

    font-size: 12px;
    .bp4-icon {
      cursor: pointer;
    }
  }

  .page-sidebar-item__selected {
    color: $sidebarBlue;
    border-width: 5px;
    border-style: solid;
    border-color: $sidebarBlue;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
  }

  .page-sidebar-item-label {
    text-align: center;
    cursor: pointer;
  }

  .page-sidebar-tool-tip {
    display: block;
  }
}
