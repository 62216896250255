@import "../../../../variables";

.wildfire-auditor-notes-form {
  .ucl-base-input-rich-text-editor-field {
    height: 13rem;
  }
}

.bp4-portal.tooltip-popover .bp4-popover2-transition-container {
  z-index: 9999;
}
