.wildfire-secondary-button {
  .e-progress-btn.e-info {
    background-color: #f0f0f0;
    font-size: 16px;
    color: #d9534f;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s ease, box-shadow 0.3s ease,
      transform 0.3s ease;

    .e-btn-content {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        padding-top: 2px;
        padding-right: 10px;
      }
    }

    &:hover {
      background-color: #e6e6e6;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      transform: translateY(-2px);
    }
  }
}
