.legacy-wildfire-evaluation-files-dialog {
  &.e-dialog {
    width: 70% !important;
    height: 70% !important;
    max-height: unset !important;
  }

  .wildfire-box-content-explorer-field,
  .bp4-form-group,
  .bp4-form-content,
  .wildfire-box-content-explorer-no-example-photo,
  .wildfire-box-content-explorer-container,
  .box-content-explorer,
  .box-content-explorer div[class^="box-content-explorer-container-"],
  .box-content-explorer div[class^="box-content-explorer-container-"] > div {
    height: 100%;

    .be.bcpr {
      height: 100%;
    }
  }

  .wildfire-box-content-explorer-container .box-content-explorer {
    width: 100%;
  }
}
