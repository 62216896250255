.go-to-checklist-button.e-progress-btn.e-spin-left {
  background-color: #2d72d2;
  box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2),
    0 1px 2px rgba(17, 20, 24, 0.1);
  color: #ffffff;

  svg {
    padding-right: 10px;
  }
}
