@import "../../../../../variables";

.iteration-engine-checklist-and-tabs {
  .steps-container {
    background-color: white;
    border-radius: 6px;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #e6ecf0;

    &__header {
      padding: 1rem;
      font-weight: 600;
      font-size: 1rem;
      border-bottom: 1px solid #e6ecf0;
      margin-bottom: 0.5rem;
    }

    &__steps {
      font-size: 15px;
      display: flex;
      flex-direction: column;

      .steps-container-step {
        padding: 0.75rem 1rem;
        display: flex;
        align-items: center;

        &__label {
          margin-left: 0.5rem;
        }
      }

      .bp4-icon-tick-circle {
        color: $green1;
      }

      .bp4-icon-cross-circle {
        color: $red1;
      }

      .bp4-icon-circle {
        color: #ffbd63;
      }

      .bp4-icon-full-circle {
        color: $mainGray7;
      }
    }
  }

  .form-navigation-container {
    background-color: white;
    border-radius: 3%;
    margin-bottom: 2rem;

    &__header {
      padding: 1rem;
      font-weight: 600;
      font-size: 1rem;
      border-bottom: 1px solid #e6ecf0;
    }

    .form-navigation__tabs {
      display: flex;
      flex-direction: column;

      .form-navigation-tab {
        text-wrap: auto;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 1.5rem;
        font-size: 15px;
        border-bottom: 1px solid #f3f3f3;
        cursor: pointer;

        /* Hover/active states */
        &:hover:not([aria-disabled="true"]) {
          background-color: #f5f8fa;
        }

        &[aria-selected="true"] {
          background-color: #e6f7ff; /* highlight active tab */
        }

        &[aria-disabled="true"] {
          color: rgba(0, 0, 0, 0.4) !important;
          cursor: not-allowed;
        }
      }
    }
  }
}
