@import "./styles/blueprint.buttons";
@import "./styles/blueprint.tabs";
@import "./styles/blueprint.fields";
@import "./styles/blueprint.overrides";
@import "./styles/syncfusion.overwrites";
@import "./styles/themes";
@import "./variables";

@import "../../../node_modules/@ucl/library/lib/styles/core-level4-styles.css";
@import "../../../node_modules/@ucl/library/lib/styles/components/styles.css";
@import "../../../node_modules/@syncfusion/ej2-image-editor/styles/fabric.css";

html,
body,
#root {
  height: 100dvh;
  background-color: $mainBackground;
  overflow: hidden;

  > .tracking-container {
    height: 100dvh;
  }

  .bp4-tab-list {
    .bp4-tab-indicator {
      background-color: rgba(13, 34, 63, 0.05);
      margin: 1px;
      border-radius: 4px;
    }
  }
}

// Remove the HTML loader
.base-loading__container {
  display: none;
}

$scrollbarColor: $mainGrayText4;
$scrollbarBackground: transparent;

*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbarColor $scrollbarBackground;
}
*::-webkit-scrollbar-track {
  background: $scrollbarBackground;
}
*::-webkit-scrollbar-thumb {
  background-color: $scrollbarColor;
  border-radius: 4.5px;
  border: 3px solid $scrollbarColor;
}

//Grid Checkbox
.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check {
  background-color: $green1;
  border-color: $green1;
}

.e-checkbox-wrapper.e-checkbox-disabled .e-frame,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame {
  border-color: $red1;
}

.field-form-field {
  scroll-margin-top: 15px;
}
