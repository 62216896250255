.welcome-applicant-container {
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
    justify-content: center;

    .welcome-applicant-container-help {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 12rem;
      justify-content: space-evenly;
    }
  }
}
