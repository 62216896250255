.wildfire-application-overview-card {
  margin-bottom: 16px;

  .ucl-card,
  .bp4-card {
    border-radius: 4px;
    box-shadow: 0px 0px 20px 0px #0000000f;
  }

  .wildfire-application-overview-card-progress-container {
    width: 100%;
  }

  .wildfire-application-overview-status-bar {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;

    &__item {
      &__default {
        color: #aaaeb3;
      }

      &__green {
        color: #26cf8b;
      }

      &__yellow {
        color: #ffbd63;
      }

      &__red {
        color: #e52a49;
      }

      &__icon {
        padding-right: 0.5rem;
      }
    }

    .application-status-bar-item {
      width: 20vw;
    }

    .application-status-bar-item:first-child {
      padding-left: 0.5rem;
      width: 20.5vw;
    }

    .landscape-review-status-bar-item {
      width: 33vw;
    }

    .landscape-review-status-bar-item:first-child {
      padding-left: 0.5rem;
      width: 33.5vw;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .wildfire-application-overview-card-header-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      .wildfire-application-overview-card-header-text-primary-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    .bp4-icon {
      margin-right: 10px;
      vertical-align: baseline;
    }
    color: #424f66;
    font-family: Lato;
    font-size: 19px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    &__buttons {
      display: flex;
      flex-direction: row;
    }
  }

  &-body {
    margin: 16px 24px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-left: 1rem;
    }

    h2 {
      color: #334056;
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;

      .wildfire-application-status-badge {
        padding: 6px 16px;
        text-align: center;
        border-radius: 16px;
        white-space: nowrap;

        &__default {
          background-color: #f5f5f5;
          color: #424f66;
        }

        &__application {
          background-color: #eaf8ff;
          color: #66ccff;
        }

        &__application-payment {
          background-color: #d4e3ff;
          color: #4d85eb;
        }

        &__ibhs-eligibity-review {
          background-color: #f4e8ff;
          color: #a25ddc;
        }

        &__changes-required {
          background-color: #ffefd9;
          color: #ffa121;
        }

        &__ready-for-re-review {
          background-color: #ffede5;
          color: #ff4d00;
        }

        &__eligibility-photos {
          background-color: #e9f8ff;
          color: #68a1bd;
        }

        &__field-evaluations {
          background-color: #ffcc003d;
          color: #f4c300;
        }

        &__ibhs-review {
          background-color: #ffeefd;
          color: #faa1f1;
        }

        &__approved {
          background-color: #caefd7;
          color: #4fc879;
        }

        &__not-approved {
          background-color: #ffefef;
          color: #ff4d4d;
        }

        &__closed {
          background-color: #ffefef;
          color: #ff4d4d;
        }
      }
    }

    &-list {
      font-family: Lato;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
    }

    &-callout.bp4-callout {
      border-radius: 4px;

      font-family: Lato;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;

      &.bp4-intent-success {
        color: #334056;
        background-color: #caefd7;
      }

      &.bp4-intent-warning {
        display: flex;
        flex-direction: column;

        color: #334056;
        background-color: #ffcc0026;

        button.bp4-button.bp4-button {
          width: fit-content;
          align-self: center;
          margin-top: 12px;

          color: inherit;
          background-color: #ffcc0055;

          &:hover {
            background-color: #ffcc0099;
          }
        }
      }

      ul {
        margin: 0;
        padding-left: 26px;
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: row-reverse;
    border-top: 1px solid #11141826;

    button.bp4-button.bp4-button.wildfire-application-overview-card-footer-navigation-button {
      margin: 16px 10px 8px 10px;
      background-color: #5a84e4;
    }

    button.bp4-button.bp4-button.wildfire-application-overview-card-footer-certificate-button {
      margin: 16px 10px 8px 10px;
      color: #44ba50;
      background-color: #cdf4d0;

      .bp4-icon {
        padding-top: 3px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    margin: 10px;

    &-body {
      margin: 0 0px;

      &-column {
        flex: 0 auto;

        h2 {
          margin: 16px 4px;
        }
      }

      &-list {
        padding-left: 24px;
        margin-top: 0;
      }
    }

    .wildfire-application-overview-card-footer {
      display: block;
      button {
        width: 95%;
      }
    }

    .wildfire-application-overview-card-body {
      flex-direction: column;
      .wildfire-application-overview-card-body-column {
        h2 {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 100%;

          span {
            margin-top: 10px;
          }
        }
      }
    }

    .wildfire-application-overview-card-header {
      flex-direction: column;
      font-size: 17px;

      .wildfire-application-overview-card-header-text-primary-info {
        flex-wrap: nowrap;
        padding-bottom: 10px;
      }

      .wildfire-application-overview-card-header-text-secondary-info {
        display: none;
      }
    }

    .wildfire-application-overview-status-bar {
      &__item {
        display: none;

        &.__current {
          display: flex;
          justify-content: center;
        }
      }
      #Application {
        width: 100%;
      }

      #Eligibility\ Review {
        width: 100%;
      }

      #Field\ Evaluations {
        width: 100%;
      }

      #IBHS\ Review {
        width: 100%;
      }

      #Designation {
        width: 100%;
      }
    }
  }
}
