.service-options-display-container {
  display: flex;
  flex-direction: row;
  width: 100%;

  .ucl-card,
  .bp4-card {
    border-radius: 6px;
    box-shadow: none;
  }

  .bp4-card.bp4-elevation-0.self-service-only {
    height: 100%;
  }

  .self-service-only {
    background-color: rgba(116, 116, 116, 0.39);
  }

  .ucl-card {
    margin: 0 12px 24px 12px;
    border: 1px solid #afb7c4;

    &__body {
      padding: 0;
      overflow-y: unset !important;
    }

    .ucl-card-header {
      color: #334056;
      border-radius: 6px 6px 0 0;
      font-family: Lato;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
    }

    .service-options-info-card-header-self-service-only {
      color: red;
      padding-left: 1rem;
    }

    .service-options-info-card-body {
      padding: 12px 24px;

      font-family: Lato;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;

      ul {
        padding-left: 16px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .service-options-display-container {
    flex-wrap: wrap;
  }
}
