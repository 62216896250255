.sidebar-menu {
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__button-wrapper {
    margin-top: 10px;

    button.bp4-button.bp4-button.bp4-minimal,
    button.bp4-button.bp4-button.bp4-minimal:disabled {
      background-color: unset;
      display: flex;
      justify-content: center;
      margin: 0;
      width: 220px;
    }

    button.bp4-minimal.bp4-intent-primary.bp4-active {
      background: rgba(45, 114, 210, 0.2);
    }

    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      text-align: center;
      color: #515e75;
      margin-right: 1rem;
    }
  }
}
