@import "../../../../variables";

.wildfire-applicant-iteration-engine-change-request-details {
  .e-btn.e-primary.app-wie-change-request-details-submit-button {
    border-color: #57bb4e;
    background: #63d458;
    color: #052a01;
    opacity: 0.9;
    &:hover {
      border-color: #57bb4e;
      background: #63d458;
      color: #052a01;
      opacity: 1;
    }
    &:disabled {
      opacity: 0.5;
    }
  }

  .app-wie-change-request-details-info {
    min-height: 50px;
    background-color: #eee;
    padding: 20px;
    box-shadow: 0px 5px 8px -2px #aaaaaaa6;
    margin-bottom: 1px;

    h4 {
      margin: 0;
      padding-left: 5px;
      font-weight: 500;
      display: inline-block;
    }
    span {
      padding-left: 5px;
      display: inline-block;
    }
    span.app-wie-change-request-details-info-emphasis {
      text-decoration: underline;
    }
  }

  .app-wie-change-request-details-content {
    display: grid;
    grid-template-columns: 1fr 400px;
    height: calc(100vh - 250px);

    .app-wie-change-request-details-form {
      padding: 15px;
      padding-top: 10px;
      overflow: auto;
    }
    .app-wie-change-request-details-comments {
      background-color: #e7ecf0;
      box-shadow: -5px 5px 8px -2px #aaaaaa5c;
      padding: 15px;
      padding-top: 20px;
      flex-direction: column;
      height: calc(100vh - 270px);

      .comment-list-scroll-container {
        min-height: 60px;
        max-height: calc(100% - 294px);
        overflow-y: auto;
      }

      .comment-list-view-title {
        font-weight: 500;
        margin-bottom: 15px;
      }
      .comment-list-view {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-top: 0px;

        &_header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 4px;

          &_name {
            display: flex;
            color: #727e8e;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 20px;
            margin-bottom: 20px;
          }
        }

        &_header_indicator {
          .bp4-tag {
            padding: 5px 16px;
            font-weight: 600;
          }

          // Resolved
          .bp4-tag.bp4-intent-success {
            background-color: $green3;
            color: $green1;
          }

          // Addressed
          .bp4-tag.bp4-intent-primary {
            background-color: $blue3;
            color: $mainBlueText2;
          }

          // Unresolved
          .bp4-tag.bp4-intent-warning {
            background-color: $orange2;
            color: $orange1;
          }

          // Unaddressed
          .bp4-tag.bp4-intent-danger {
            background-color: $tagRedBackground;
            color: $tagRedText;
          }

          .bp4-icon {
            margin-right: 5px;
          }
        }

        .change-request-comment {
          color: rgba(6, 34, 65, 0.5);

          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;

          &_old,
          &_new {
            color: #062241;
          }
        }

        &_item {
          margin-top: 8px;
          overflow: auto;
          display: flex;
          flex-direction: column;

          &_line-1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            white-space: initial;
            overflow-wrap: anywhere;

            &_name {
              display: flex;
              color: #062241;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 20px;
              margin-right: 10px;
            }

            &_time {
              display: flex;
              color: $mainGrayText4;
              font-size: 13px;
              letter-spacing: 0;
              line-height: 17px;
              margin-bottom: 12px;
            }

            &_delete {
              display: flex;
              margin-left: auto;

              .bp4-button {
                background-color: transparent;
              }

              .bp4-icon {
                color: scale-color($mainGrayText4, $lightness: 10%);
              }
            }
          }

          &_line-2 {
            margin: 0;
            color: #062241;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            overflow-wrap: anywhere;
            padding-bottom: 12px;
          }
        }
      }

      .comment-input {
        margin-top: auto;
        height: 150px;

        &_actions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          padding-bottom: 10px;

          .bp4-button.comment-input__submit-button {
            margin-right: 0;
            margin-top: 12px;
          }
        }

        &_resolve-button.bp4-button,
        &_unresolve-button.bp4-button {
          width: 100px;
          margin: 6px 5px 8px 0;
        }

        .comment-textarea {
          text-wrap: wrap;
          overflow-y: auto;
          border: 1px solid #ccc;
        }
      }
    }
  }
}

@media screen and (max-width: 1275px) {
  .wildfire-applicant-iteration-engine-change-request-details {
    .app-wie-change-request-details-info {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;
      padding: 10px;

      > div {
        width: 90%;
      }
    }
    .app-wie-change-request-details-content {
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      overflow: auto;
    }
  }

  .wildfire-applicant-iteration-engine-change-request-details
    .app-wie-change-request-details-content {
    .app-wie-change-request-details-comments {
      height: calc(100vh - 186px);

      .comment-input {
        height: 180px;
      }

      .comment-input_actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .bp4-button.comment-input__submit-button {
          margin: 10px 0;
          width: 80%;
        }
      }
    }
  }
}
