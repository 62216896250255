.readonly-warning-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fdced2;
  border: 1px solid #e6ecf0;
  padding: 5px;
  border-radius: 1%;
  justify-content: space-between;

  margin-top: 15px;
  margin-bottom: 10px;

  .e-message {
    display: flex;
    align-items: center;
    flex-grow: 1;
    background: #fdced2;
    border-color: #fdced2;

    .e-msg-content {
      padding-right: 15px;
      flex-grow: 1;
    }

    .e-msg-close-icon {
      min-height: 0px;
      height: 12px;
      min-width: 0px;
      margin-left: auto;
    }
  }

  &__icon {
    color: #ff002b;
    padding-left: 10px;
  }
}
