@import "../../../../variables";

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.change-request-navigator-element-active {
  outline: 1px solid #2d72d2;
  border-radius: 2px;
  outline-style: dotted;
  outline-offset: 4px;
}

.comment-pannel-v2 {
  display: flex;
  flex-direction: column;
  padding-left: $mediumSpacing;
  padding-right: $mediumSpacing;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  animation: slide-in 0.3s ease-in-out;

  .loading__container {
    margin-top: 0;
  }

  .message {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fdced2 !important;

    .e-msg-icon {
      color: #ff002b !important;
    }
  }

  .comment-container-v2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 155px);

    &__no-selection {
      font-size: 16px;
      line-height: 20px;
      .bp4-icon {
        margin-right: 6px;
        margin-bottom: 2px;
      }
    }

    .comment-container-v2-no-fields-navigation {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .comment-container-v2-no-fields-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 50%;
      color: $mainGrayText4;
    }

    .change-request-navigator-v2 {
      .change-request-navigator-v2-nav {
        border: 1px solid #fea5005c;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
      }
      &__buttons {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 0.5rem;

        .bp4-button {
          background: #f5eadb;
          margin: 0;
          border-radius: 0;
          color: #fea500;
          padding: 1rem;
          border: none;
          box-shadow: none;

          .bp4-icon {
            color: $orange1;
          }
        }

        .bp4-button.bp4-disabled {
          color: #999691;
        }

        .change-request-navigator-v2-label {
          padding: 1rem;
          background: #f5eadb;
          color: #fea500;
          border-right: 0.1px solid #fea500;
          border-left: 0.1px solid #fea500;
          width: 100%;
          text-align: center;
        }
      }
    }

    .comment-list-view .change-request-navigator-v2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      height: 32px;
      min-height: 50px;
      border-radius: 6px;
      background-color: rgba(255, 189, 99, 0.2);
      color: $orange1;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;

      .bp4-button {
        color: $orange1;
        font-size: 14px;
        line-height: 17px;
        padding-top: 7px;
      }

      .bp4-button:hover {
        color: darken($orange1, 10%);
        .bp4-icon svg {
          fill: darken($orange1, 10%);
        }
      }

      &_go-left {
        display: flex;
        background-color: transparent;

        .bp4-button {
          padding-left: 0;
          color: $orange1;
        }
      }

      .bp4-button:focus {
        outline-offset: -2px;
      }

      &_state {
        display: flex;
        margin: auto 0;
      }

      &_go-right {
        display: flex;

        .bp4-button {
          color: $orange1;
          padding-right: 0;
        }
      }

      &_place-holder {
        width: 30px;
        height: 32px;
      }
    }

    .comment-list-scroll-container {
      overflow-y: auto;
      max-height: calc(100vh - 660px);
    }

    .comment-list-view_header_ishidden {
      display: flex;
      margin-bottom: 15px;

      .e-message .e-msg-content {
        font-size: 14px;
      }
    }

    .comment-list-view {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin-top: 0px;

      &_empty {
        display: flex;
        justify-content: center;
        margin: auto 0;
        color: #bbb;
      }

      &_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        &_name {
          display: flex;
          color: #727e8e;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 20px;
        }
      }

      &_header_indicator {
        .bp4-tag {
          padding: 5px 16px;
          font-weight: 600;
        }

        // Resolved
        .bp4-tag.bp4-intent-success {
          background-color: $green3;
          color: $green1;
        }

        // Addressed
        .bp4-tag.bp4-intent-primary {
          background-color: $blue3;
          color: $mainBlueText2;
        }

        // Unresolved
        .bp4-tag.bp4-intent-warning {
          background-color: $orange2;
          color: $orange1;
        }

        // Unaddressed
        .bp4-tag.bp4-intent-danger {
          background-color: $tagRedBackground;
          color: $tagRedText;
        }

        .bp4-icon {
          margin-right: 5px;
        }
      }

      .change-request-comment {
        color: rgba(6, 34, 65, 0.5);

        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;

        &_old,
        &_new {
          color: #062241;
        }
      }

      .internal-comment {
        border-left: 4px solid #007bff;
        background: linear-gradient(to right, #f8f9fa, #e9ecef);
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      &_item {
        margin-top: 8px;
        overflow: auto;
        display: flex;
        flex-direction: column;

        &_line-1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          white-space: initial;
          overflow-wrap: anywhere;

          &_name {
            display: flex;
            font-weight: bold;
            color: #062241;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            margin-right: 10px;

            &_icon {
              margin: 4.2px 4px 0 0;
            }
          }

          &_time {
            display: flex;
            font-style: italic;
            color: #6c757d;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 17px;
            margin-bottom: 12px;
          }

          &_delete {
            display: flex;
            margin-left: auto;

            .bp4-button {
              background-color: transparent;
            }

            .bp4-icon {
              color: scale-color($mainGrayText4, $lightness: 10%);
            }
          }
        }

        &_line-2 {
          display: flex;
          margin: 0;
          color: #062241;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
          overflow-wrap: anywhere;
          padding-bottom: 12px;
        }
      }
    }

    .comment-input {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      height: 310px;

      &_actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
        align-items: center;

        button {
          margin: 0px;
        }
      }

      .comment-input-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      &_resolve-button.bp4-button,
      &_unresolve-button.bp4-button {
        width: 100px;
        margin: 6px 5px 8px 0;
      }

      .comment-mode-selector {
        button.bp4-button {
          margin: 5px 0;
          padding: 4px 10px 5px 10px;
        }

        .comment-mode-selector-tooltip-container {
          width: 100%;
        }

        .comment-mode-selector-tooltip {
          padding: 0.5rem;
        }
      }

      .comment-textarea {
        text-wrap: wrap;
        overflow-y: auto;
      }
    }
  }
}
