@import "../variables";

.e-grid .e-filtered::before {
  color: $orange1;
  font-weight: bold;
}

.e-grid .e-rowcell {
  cursor: default;
}

.e-grid .e-toolbar {
  border-top: 0;
  border-bottom: 1px solid $gridBorderColor;
}

.e-toolbar,
.e-toolbar .e-toolbar-items {
  background: transparent;
}
.e-toolbar .e-tbar-btn,
.e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-tbar-btn.e-btn.e-tbtn-txt
  .e-icons.e-btn-icon,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  background: transparent;
  font-size: 13px;
  //box-shadow: none;
  color: $mainGrayText3;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt:active,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt:focus,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt:hover {
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  color: $mainGray6;

  .e-icons.e-btn-icon,
  .e-tbar-btn-text {
    background: transparent;
    border-color: transparent;
    border-radius: 0;
    color: $mainGray6;
  }
}

.e-toolbar
  .e-toolbar-items
  .e-toolbar-item
  .e-tbar-btn.e-btn.e-tbtn-txt:active {
  background: $mainBackground;
}

.e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator).e-search-wrapper {
  padding: 0;
}
.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
  height: 43px;
  border-color: transparent;
}
