.external-form-container {
  .external-form-footer {
    display: flex;
    justify-content: flex-end;

    position: fixed;
    bottom: 0;
    background: #fff;
    width: 99%;
    padding: 15px 20px 10px 15px;
    box-shadow: -2px -6px 9px -4px #ededed;
    margin-left: -30px;

    button.bp4-button {
      border: none;
      box-shadow: none;
    }

    button.bp4-button.external-form-submit-button {
      color: #ffffff;
      background-color: #4dd45a;

      .bp4-icon {
        color: inherit;
      }
    }
  }
}
