@import "../../../variables";

div.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  min-height: 64px;
  position: fixed;
  top: 0;
  // box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);

  &_left {
    img {
      margin: 13px 14px 14px 16px;
    }

    &_title {
      height: 25px;
      color: $mainBlackText;
      font-size: 21px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 25px;
    }
  }

  &_center {
    margin-left: 45%;
  }

  &__checklist-title {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 22px;
  }

  .bp4-navbar-group.bp4-align-right {
    .bp4-button {
      min-width: 100px;
    }

    .bp4-button.bp4-intent-warning {
      min-width: 170px;
    }
  }
}

.external-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  min-height: 64px;
  box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);
  background-color: #0f6735 !important;
  top: -4rem;

  &_left {
    img {
      margin: 13px 14px 14px 0px;
    }

    &_title {
      color: #fff;
      font-size: 17px;
    }
  }

  .bp4-divider {
    background-color: $mainGray5;
    width: 0.01rem;
    height: 1.5rem;
    margin: 0px 10px 0px 10px;
  }

  &_center {
    margin-left: 45%;
  }

  &__sub-title {
    font-size: 17px;
    color: #fff;
  }

  .bp4-navbar-group.bp4-align-right {
    .bp4-button {
      min-width: 100px;
    }

    .bp4-button.bp4-intent-warning {
      min-width: 170px;
    }
  }
}
