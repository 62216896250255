$status-colors: (
  "default": (
    #f5f5f5,
    #424f66,
  ),
  "1": (
    // ApplicationInitiated
    #eaf8ff,
    #66ccff,
  ),
  "10": (
    // ApplicationFee
    #d4e3ff,
    #4d85eb,
  ),
  "2": (
    // EligibilityReviewed (ibhs-eligibility-review)
    #f4e8ff,
    #a25ddc,
  ),
  "changes-required": (
    // Keep as string since it's a special state
    #ffefd9,
    #ffa121,
  ),
  "ready-for-re-review": (
    // Keep as string since it's a special state
    #ffede5,
    #ff4d00,
  ),
  "3": (
    // EvaluatorAssignment
    #ffcc003d,
    #f4c300,
  ),
  "7": (
    // IBHSReview
    #ffeefd,
    #faa1f1,
  ),
  "approved": (
    #caefd7,
    #4fc879,
  ),
  "not-approved": (
    #ffefef,
    #ff4d4d,
  ),
);

.fortified-application-overview-card {
  margin-bottom: 16px;

  .ucl-card,
  .bp4-card {
    border-radius: 4px;
    box-shadow: 0px 0px 20px 0px #0000000f;
  }

  .fortified-application-overview-card-progress-container {
    width: 100%;
  }

  .application-overview-status-bar {
    display: flex;
    flex-direction: row;
    padding-top: 1rem;

    &__item {
      width: 16.66%; // For 6 equal segments

      &:first-child {
        padding-left: 0.5rem;
      }

      &__default {
        color: #aaaeb3;
      }

      &__green {
        color: #26cf8b;
      }

      &__yellow {
        color: #ffbd63;
      }

      &__red {
        color: #e52a49;
      }

      &__icon {
        padding-right: 0.5rem;
      }
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #424f66;
    font-family: Lato;
    font-size: 19px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    .fortified-application-overview-card-header-text {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      &-primary-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    .bp4-icon {
      margin-right: 10px;
      vertical-align: baseline;
    }
  }

  &-body {
    margin: 16px 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-left: 1rem;
    }

    h2 {
      color: #334056;
      font-family: Lato;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
    }

    &-list {
      font-family: Lato;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
    }

    &-callout.bp4-callout {
      border-radius: 4px;
      font-family: Lato;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;

      &.bp4-intent-success {
        color: #334056;
        background-color: #caefd7;
      }

      ul {
        margin: 0;
        padding-left: 26px;
      }
    }
  }

  .fortified-application-status-badge {
    padding: 6px 16px;
    text-align: center;
    border-radius: 16px;
    white-space: nowrap;

    @each $status, $colors in $status-colors {
      &__#{$status} {
        background-color: nth($colors, 1);
        color: nth($colors, 2);
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: row-reverse;
    border-top: 1px solid #11141826;

    button.bp4-button.bp4-button.fortified-application-overview-card-footer-navigation-button {
      margin: 16px 10px 8px 10px;
      background-color: #5a84e4;
    }

    .fortified-application-overview-card-footer-certificate-button-container {
      margin: 16px 10px 8px 10px;
    }
  }

  // Mobile responsive styles
  @media screen and (max-width: 1000px) {
    margin: 10px;

    &-body {
      margin: 0;

      &-column {
        flex: 0 auto;

        h2 {
          margin: 16px 4px;
        }
      }

      &-list {
        padding-left: 24px;
        margin-top: 0;
      }
    }

    &-footer {
      display: block;
      button {
        width: 95%;
      }
    }

    &-header {
      flex-direction: column;
      font-size: 17px;

      &-text {
        &-primary-info {
          flex-wrap: nowrap;
          padding-bottom: 10px;
        }

        &-secondary-info {
          display: none;
        }
      }
    }
  }
}
