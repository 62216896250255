@import "../variables";

.bp4-tag.bp4-intent-danger.bp4-minimal {
  background-color: $tagRedBackground;
  color: $tagRedText;
}

.bp4-tag.bp4-intent-success.bp4-minimal {
  background-color: $tagGreenBackground;
  color: $tagGreenText;
}

.bp4-tag.bp4-minimal.bp4-round {
  width: 94px;

  .bp4-fill {
    font-size: 14px;
    line-height: 24px;
    margin: 2px 0;
    text-align: center;
  }
}

.bp4-portal.tooltip-popover {
  .bp4-popover2-arrow {
    path {
      fill: $tooltipBackground;
    }
  }
  .bp4-popover2-content {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    background-color: $tooltipBackground;
    color: $tooltipMainText;
  }
}

.bp4-portal.tooltip-popover.checklist-item-tooltip-popover__status-disabled {
  .bp4-popover2-content {
    min-width: 500px;

    ul li::before {
      content: "\2022";
      color: white;
      font-weight: bold;
      width: 1em;
      margin-left: -2em;
    }
  }
}

.bp4-toast-container {
  z-index: 999999;
}
.bp4-toast {
  .bp4-toast-message {
    font-size: 16px;
    color: $mainBlackText;
    h3 {
      margin-top: 0;
    }
  }
  .bp4-button-group .bp4-button .bp4-icon {
    color: $mainBlackText !important;
  }

  .bp4-button-group {
    align-self: flex-start;

    button.bp4-button {
      margin-right: 0;
    }
  }

  &.bp4-intent-danger {
    background-color: $red2;
  }

  &.bp4-intent-success {
    background-color: $green4;
  }
}

// Dialog
.bp4-dialog {
  .bp4-dialog-header {
    padding: 0 5px 0px 10px;

    .bp4-heading {
      font-size: 16px;
      font-weight: bold;
    }

    button.bp4-button.bp4-button.bp4-minimal {
      @include bp4-button-minimal;
      background: none;
      margin-right: 0;
    }
  }
}

.base-field {
  span.bp4-popover2-target {
    width: 100%;
  }
}

.base-field .bp4-label.ucl-field-label span.bp4-popover2-target {
  width: 16px;
}
