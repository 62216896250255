.wildfire-report-header {
  display: flex;
  flex-direction: row;

  margin-bottom: 15px;

  h1 {
    margin: 0 0 0 8px;
    padding-top: 3px;

    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
  }
}

.wildfire-report-viewer {
  height: calc(100vh - 140px);
}
