@import "../../../variables";

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wildfire-admin-iteration-engine {
  .wildfire-iteration-engine {
    display: flex;
    flex-direction: column;

    &__callout {
      margin: 0 0 16px 10px;
      width: unset;
    }

    .wildfire-iteration-engine__right-panel {
      .comment-container-v2 {
        animation: fade-in 0.3s ease-in-out;
      }

      .bp4-tabs {
        margin-left: 10px;
      }

      .bp4-tab-indicator-wrapper {
        display: none !important;
      }

      .bp4-tab {
        flex: 1;
        margin: 0;
        text-align: center;
        justify-content: center;
      }

      .bp4-tab-list {
        border-bottom: 1px solid rgba(17, 20, 24, 0.1490196078);
      }

      .bp4-tab[aria-selected="true"] {
        background: white !important;
        color: black;
        border-radius: 10px 10px 0 0;
        padding: 10px 0;
        border: 1px solid rgba(17, 20, 24, 0.1490196078);
        border-bottom: unset;
      }

      .bp4-tab-panel {
        margin-top: 0;
        background: white;
      }
    }

    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .left-panel {
        display: flex;
        align-items: center;
        justify-self: flex-start;
      }

      .right_panel {
        display: flex;
        justify-self: flex-end;
      }

      margin-bottom: 0.5rem;
    }

    &__content {
      display: flex;
      flex: 1;
      padding: 10px;
      overflow: auto;
      max-height: calc(100vh - 135px);

      .wildfire-iteration-engine__left-panel {
        min-width: 15rem;
        margin-right: 1rem;
        overflow-x: hidden;
      }

      .wildfire-iteration-engine__right-panel {
        overflow-x: hidden;
        width: 600px;
      }
      /* Make the center panel scrollable */
      .wildfire-iteration-engine__center-panel {
        //flex: 2;
        display: flex;
        flex-direction: column;
        overflow-y: auto; /* Enables vertical scrolling */
        width: 100%;
        background-color: white;
        border-radius: 1%;
        padding: 15px;
        border: 1px solid #e6ecf0;
        height: calc(100vh - 155px);

        .ucl-card {
          margin-bottom: 30px;
          .bp4-card {
            border-radius: 6px;
          }
        }

        .wildfire-form-container
          .field-form
          .non-compliant-label
          .ucl-label-title {
          background-color: #ff9595; /* Red Non-Compliant */
          padding: 5px;
          border-radius: 5px;
        }

        .wildfire-form-container
          .field-form
          .change-request-new
          .ucl-label-title {
          background-color: #f3bb6d;
          padding: 5px;
          border-radius: 5px;
        }

        .wildfire-form-container
          .field-form
          .change-request-addressed
          .ucl-label-title {
          background-color: #6cf; /* Blue */
          padding: 5px;
          border-radius: 5px;
        }

        .wildfire-form-container
          .field-form
          .change-request-resolved
          .ucl-label-title {
          background-color: #96e29e; /* Green */
          padding: 5px;
          border-radius: 5px;
        }

        .wildfire-form-container
          .field-form
          .internal-comment
          .ucl-label-title {
          background-color: #fccd86;
          padding: 5px;
          border-radius: 5px;
        }
      }

      .wildfire-box-content-explorer-container .box-content-explorer {
        width: 100%;
      }
    }
  }
}

.label-danger .ucl-label-title {
  background-color: $red1;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

@media screen and (max-width: 1575px) {
  .wildfire-admin-iteration-engine
    .wildfire-iteration-engine__content
    .wildfire-iteration-engine__left-panel {
    width: 175px;
    margin-right: 0;
    padding-right: 1rem;

    .evaluation-steps-container {
      min-width: 150px;
      width: 150px;
    }
    .evaluation-steps-container__steps {
      min-width: 150px;
      width: 150px;
      font-size: 14px;
    }
  }

  .evaluation-form-navigation-container {
    .bp4-tabs.bp4-vertical > .bp4-tab-list {
      overflow: hidden;
    }
    .bp4-tabs.bp4-vertical .bp4-tab-list .bp4-tab {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1445px) {
  .evaluation-forms-container.evaluation-forms-container-initialized {
    .ucl-card .bp4-collapse {
      overflow: hidden;
    }
  }
  .wildfire-admin-iteration-engine
    .wildfire-iteration-engine__content
    .wildfire-iteration-engine__center-panel {
    width: 150%;
  }
}

@media screen and (max-width: 1175px) {
  .field-form:not(.field-form-grid)
    fieldset
    .field-form-field.field-form-field-100 {
    flex-basis: 90% !important;
  }
  .wildfire-box-content-explorer-container {
    width: 90%;
  }
}

@media screen and (max-width: 1160px) {
  .field-form:not(.field-form-grid)
    fieldset
    .field-form-field.field-form-field-100 {
    flex-basis: 70% !important;
  }
  .wildfire-box-content-explorer-container {
    width: 80%;
  }
}

@media screen and (max-width: 1140px) {
  .field-form:not(.field-form-grid)
    fieldset
    .field-form-field.field-form-field-100 {
    flex-basis: 60% !important;
  }
  .wildfire-box-content-explorer-container {
    width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  .field-form:not(.field-form-grid)
    fieldset
    .field-form-field.field-form-field-100 {
    flex-basis: 55% !important;
  }
  .wildfire-box-content-explorer-container {
    width: 65%;
  }
}

@media screen and (max-width: 900px) {
  .field-form:not(.field-form-grid)
    fieldset
    .field-form-field.field-form-field-100 {
    flex-basis: 51% !important;
  }
  .wildfire-box-content-explorer-container {
    width: 60%;
  }
}
