.name-field {
  display: flex;
  flex: 1;
  flex-direction: row;

  &_input {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 10px;

    &_helper {
      height: 17px;
      color: #c0c5cf;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;
    }
  }
}
