@import "../../../../variables";

.bp4-form-group label.bp4-label {
  margin-top: 10px;
  color: #727e8e;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}

.bp4-popover-wrapper {
  display: flex;
  flex: 1 1 0;

  .bp4-popover-target {
    width: 100%;
  }
}

.label-title {
  $labelLeftPadding: 4px;
  padding: 1px $labelLeftPadding;
}

.is-selected-field .label-title {
  background-color: #afb5c2;
  border-radius: $standardBorderRadius;
}

.bp4-label.has-active-internal-comment .label-title {
  $labelLeftPadding: 4px;
  background-color: #fccd86;
  border-radius: $standardBorderRadius;
  padding: 1px $labelLeftPadding;
}

.is-selected-field.has-active-internal-comment .label-title {
  background-color: #ffb13b;
}

.bp4-label.has-active-change-request .label-title {
  $labelLeftPadding: 4px;
  background-color: #fff4cb;
  border-radius: $standardBorderRadius;
  padding: 1px $labelLeftPadding;
}

.is-selected-change-request.has-active-change-request .label-title {
  background-color: #fecf02;
}

.is-resovled-change-request.has-active-change-request .label-title {
  background-color: rgba(38, 205, 136, 0.5);
}

.is-selected-change-request.is-resovled-change-request.has-active-change-request
  .label-title {
  background-color: $green1;
  color: $white;
}
