@import "../../../../variables";

.email-notification {
  height: 20px;
  display: flex;
  justify-content: flex-end;
  display: flex;
  margin-bottom: 16px;

  a {
    display: flex;
    font-size: 16px;
    line-height: 20px;
    color: $mainBlueText2;
  }

  .bp4-icon {
    margin-right: 10px;
    margin-top: 3px;
  }
}
