@import "../../../variables";

.user-profile-tab {
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.user-profile-tab > div {
  margin-right: 0.3rem;
}

.bp4-menu-item {
  text-align: center;
}
