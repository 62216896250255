.form-progress-container {
  padding: 0;
  margin: 0;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  animation: slide-in 0.3s ease-in-out;

  .vertical-line {
    margin: -15px 0 -15px 28px;
    height: 40px;
    border-left: 2px solid #e8eef4;
  }

  &__steps {
    width: 300px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
    overflow-y: auto;

    &__header {
      margin-top: 20px;
      margin-left: 12px;

      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
      color: #515e75;
      text-align: left;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 15px;
      gap: 10px;

      &__page {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 220px;

        .bp4-icon {
          margin-right: 0.5rem;

          svg {
            width: 20px;
            height: 20px;
          }

          &.bp4-icon-circle {
            color: #afb7c4;
          }

          &.bp4-icon-ring {
            color: #ffbd63;
          }

          &.bp4-icon-tick-circle {
            color: hsl(156, 69%, 48%);
          }
        }

        button.bp4-button.bp4-button.bp4-minimal {
          background-color: unset;
          margin: 0;
        }

        button.bp4-minimal.bp4-intent-primary.bp4-active {
          background: rgba(45, 114, 210, 0.2);
        }

        button.bp4-minimal.bp4-intent-primary.bp4-disabled span {
          color: #a7aeb8;
        }

        span {
          display: flex;

          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #515e75;
          text-align: left;
        }
      }

      &__badge {
        display: flex;
        align-items: center;
        width: 40px;

        .bp4-tag.bp4-round {
          min-height: 10px;
          min-width: 10px;

          color: #ffb54f;
          background-color: #ffefd9;

          font-size: 12px;
          font-weight: 700;
          line-height: 10px;
        }
      }
    }
  }
}
