@import "../../../variables";

.bp4-menu {
  max-height: 310px;
  overflow-y: scroll;
}

.bp4-dialog {
  background: $white !important;
  font-size: $standardFontSize;

  form {
    display: block;
  }

  .bp4-icon {
    margin-top: 2px;
  }

  .bp4-form-group {
    margin-top: 1rem;

    .comment-textarea {
      text-wrap: wrap;
      overflow-y: auto;
    }
  }

  .bp4-dialog-footer {
    margin-top: 8px;
    text-align: right;

    .bp4-button {
      min-width: 100px;
      margin-right: 0;

      .bp4-button-text {
        font-size: $standardFontSize;
      }
    }

    button:first-of-type.bp4-button {
      margin-right: 8px;
    }
  }
}

.legacy-evaluation-files-dialog,
.post-redesignation-file-uploader-dialog {
  width: calc(100vw - 5vw) !important;
  height: calc(100vh - 10vh);
  [class^="box-content-explorer"],
  .be {
    height: 100%;
  }
}

.certificate-dialog {
  width: 85vh;
  height: 75vh;
}

.comment-dialog {
  width: calc(100% - 20rem);
  max-width: 1200px;
  min-width: 400px;

  .bp4-dialog-body {
    max-height: calc(100vh - 24rem);
    overflow-y: scroll;
    overflow-x: auto;
    margin: 0;
  }

  .bp4-dialog-footer {
    margin-top: 0;
    height: 250px;
  }

  .disclaimer {
    margin-left: 15px;
    margin-top: 15px;
    color: $mainGrayText1;
    text-align: left;
    font-weight: bold;
  }

  .comment-card {
    .comment-card-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      .comment-card-actions {
        display: flex;
        align-items: center;

        .comment-card-viewer-name {
          font-size: small;
        }
      }
    }

    .comment-card-date {
      font-size: small;
      color: $mainGrayText1;
    }

    .comment-card-view-button {
      span {
        font-size: small;
      }
    }
  }
}

.dialog-footer-children {
  .field-label {
    text-align: left;
  }
}

.comment-create-footer {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  .add-comment-button {
    min-width: 20px !important;
    background-color: #93ecb8;
  }
}

.auditor-notes-dialog.bp4-dialog,
.evaluator-notes-dialog.bp4-dialog {
  max-height: 500px;

  .notes-textarea {
    text-wrap: wrap;
    overflow-y: auto;
  }

  .bp4-dialog-body {
    overflow-y: scroll;
  }
}

.rejection-reasons-readonly {
  display: flex;
  flex-wrap: wrap;

  .rejection-reason {
    padding: 0.4rem;
  }

  .rejection-reasons-textarea {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;

    .rejection-reasons-notes {
      text-wrap: wrap;
      overflow-y: auto;
      max-width: 470px;
      min-width: 470px;
    }
  }
}

.legacy-evaluation-data-dialog {
  min-width: 40rem;
  height: 80vh;
  overflow-y: auto;
  .legacy-evaluation-table {
    border-collapse: collapse;
    width: 100%;

    .legacy-evaluation-header {
      text-align: left;
      border: 1px solid black;
      padding: 8px;
    }

    .legacy-evaluation-cell {
      text-align: center;
      border: 1px solid black;
      padding: 8px;
    }

    .legacy-evaluation-label {
      text-align: left;
    }

    .legacy-evaluation-data {
      text-align: left;
    }
  }
}

.create-evaluation-dialog {
  width: 100%;
  max-width: 700px;
  .redesignation-dialog-evaluation-values {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    > div {
      display: contents;
    }

    .readonly-container.bp4-input.bp4-disabled {
      color: #373737;
      background-color: #f8fafc;
    }
  }

  .create-evaluation-search-help-text {
    text-align: center;
    padding-top: 1rem;
    color: #727e8e;
  }
}

#update-user-form {
  .base-field.user-update-active-checkbox {
    .bp4-form-content {
      display: flex;
      align-items: baseline;
    }
  }
}

.fortified-admin-dialog-buttons {
  display: flex;
  flex-direction: column;
  button {
    margin: 10px 30px 10px 30px !important;
  }
}
