.bcpr,
.be {
  margin-top: 12px;
  height: 400px;

  .be-header {
    display: none;
  }

  .be-breadcrumb-more,
  .be-breadcrumb {
    pointer-events: none;
    cursor: default;
  }
}

.file-url-field {
  button.bp4-button.bp4-button.bp4-minimal {
    margin: 12px 0;
  }

  .bp4-dialog {
    padding-bottom: unset;
  }
}

.file-url-field__no-files {
  color: #727e8e;
  margin-top: 6px;
}

.file-field-tooltip-container {
  width: 100%;
}

.file-field-tooltip {
  padding: 0.5rem;
}
