@import "../../../../../../variables";

.bp4-popover2 .bp4-popover2-content {
  .status-disabled_item {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #e6ecf0;
    margin-top: 8px;

    &_name {
      display: flex;
    }
    &_completion-role {
      display: flex;
    }
  }
}

.checklist-item__status-disabled {
  font-size: 14px;
  line-height: 24px;
  color: $mainGrayText2;

  .bp4-icon {
    margin-right: 6px;
    margin-bottom: 2px;
  }

  .bp4-popover2-target {
    text-align: center;
  }
}

.checklist-item__tooltip-header {
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
  font-size: 14px;
  line-height: 17px;
  color: $tooltipHeaderText;
}
