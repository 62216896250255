.external-fh-forms {
  overflow-y: scroll;
  margin-top: -3rem;
  padding-bottom: 11rem;
  padding-left: 2rem;
  padding-right: 2rem;

  form.field-form {
    .ucl-base-input-numeric-field-container .e-numeric.e-input-group {
      max-width: 200px;
    }
  }
}
