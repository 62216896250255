@import "../../../variables";

//TODO: Re-implement footer and grid sizes when feature toggle is removed for metrics. Commented out for now.

//UCL Grid Styles
.wildfire-board {
  &__add-comment-template-button {
    position: absolute;
    top: 72px;
    right: 10px;
  }

  .grid-filters-module {
    top: 4.5rem;
  }

  .e-content {
    // max-height: calc(100vh - 20.5rem) !important;

    .icon-grid-column {
      display: flex;
      align-items: center;
      justify-content: center;

      .active-grid-icon {
        color: $mainBlueText1;
      }

      :hover {
        cursor: pointer;
        color: $mainBlueText1;
      }

      .grid-icon-value {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: red;
        width: 18px;
        height: 18px;
        color: white;
        border-radius: 50%;
        text-align: center;
        margin-left: -1.6rem;
        position: absolute;
        margin-top: -0.8rem;
      }
    }

    .icon-grid-comment-column {
      justify-content: left; // prevents icon from being pushed out of view by centered text preview of CommentsGridColumn.
    }

    .certificate-grid-column:hover {
      cursor: pointer;
      span {
        color: $mainBlueText1;
      }
    }
  }

  .ucl-grid-container:has(+ .grid-metrics.grid-metrics-expanded)
    .e-grid.ucl-grid
    .e-gridcontent
    .e-content {
    height: calc(100vh - 380px) !important;
  }

  .ucl-grid-container:has(+ .grid-metrics.grid-metrics-empty)
    .e-grid.ucl-grid
    .e-gridcontent
    .e-content {
    height: calc(100vh - 300px) !important;
  }

  .ucl-grid-container.has-tabs:has(+ .grid-metrics.grid-metrics-expanded)
    .e-grid.ucl-grid
    .e-gridcontent
    .e-content {
    height: calc(100vh - 372px) !important;
  }

  .grid-metrics .bp4-collapse-body:has(> .ucl-grid) {
    max-height: 208px;
    overflow-y: auto;
  }

  .grid-metrics.grid-metrics-expanded .ucl-card__body .ucl-card__body {
    height: 132px;
  }

  .ucl-card__body {
    padding-right: 4rem;
    min-height: 0px;
    .ucl-subcard {
      border-bottom: none !important;
    }
    .grid-metrics__picker-button-container {
      border-bottom: none !important;
    }
    .ucl-card__body {
      display: flex !important;
      flex-direction: row !important;
      justify-content: flex-start !important;
      //overflow-x: auto;
      .bp4-form-group {
        white-space: nowrap;
      }
    }
  }
}

.e-sidebar-context {
  .wildfire-board {
    .grid-filters-module {
      top: 1rem;
    }
  }
}

.admin-tabs
  .grid-metrics.grid-metrics-expanded
  .ucl-card__body
  .ucl-card__body {
  height: 73px;
}
