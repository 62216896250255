.wildfire-admin-update-dialog-container {
  textarea.bp4-input {
    height: 7rem;
    white-space: wrap;
    overflow-y: auto;
    text-wrap: wrap;
  }

  textarea.bp4-input:disabled {
    background-color: #f8fafc;
    color: rgba(95, 107, 124, 0.6);
  }

  @media screen and (max-width: 1200px) {
    .field-form:not(.field-form-grid)
      fieldset
      .field-form-field.field-form-field-100 {
      flex-basis: 100% !important;
    }
  }
}
