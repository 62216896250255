.wildfire-form-page {
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-right: 10px;

  .wildfire-form-content {
    height: 100%;
    transform: translate(0px, 0px);
    width: 100%;
    .wildfire-form-content__header {
      display: none;
    }

    .ucl-card {
      &__body {
        padding: 10px 15px;
      }
    }

    > .ucl-card,
    > .ucl-card .wildfire-form-primary-card,
    > .ucl-card .wildfire-form-primary-card .bp4-collapse,
    > .ucl-card .wildfire-form-primary-card .bp4-collapse .bp4-collapse-body {
      height: 100% !important;
    }
    > .ucl-card {
      overflow: hidden;
    }
    > .ucl-card .wildfire-form-primary-card .bp4-collapse,
    > .ucl-card .wildfire-form-primary-card .bp4-collapse .bp4-collapse-body {
      transform: none !important;
    }
    > .ucl-card
      .wildfire-form-primary-card
      .bp4-collapse
      .bp4-collapse-body
      .ucl-card__body {
      overflow-y: auto;
      height: 85%;
    }

    .wildfire-form-primary-card {
      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        font-weight: bold;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }

      &__header-buttons {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .wildfire-form-progress {
    padding: 0;
    margin: 0;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    animation: slide-in 0.3s ease-in-out;

    .vertical-line {
      margin: -15px 0 -15px 28px;
      height: 40px;
      border-left: 2px solid #e8eef4;
    }

    .wildfire-dashboard-navigation-button-container {
      height: 50px;
      max-height: 50px;
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__steps {
      width: 300px;
      display: flex;
      flex-direction: column;
      height: calc(100% - 50px);
      overflow-y: auto;

      &__header {
        margin-top: 20px;
        margin-left: 12px;

        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        color: #515e75;
        text-align: left;
      }

      &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 15px;
        gap: 10px;

        &__page {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 220px;

          .bp4-icon {
            margin-right: 0.5rem;

            svg {
              width: 20px;
              height: 20px;
            }

            &.bp4-icon-circle {
              color: #afb7c4;
            }

            &.bp4-icon-ring {
              color: #ffbd63;
            }

            &.bp4-icon-tick-circle {
              color: hsl(156, 69%, 48%);
            }
          }

          button.bp4-button.bp4-button.bp4-minimal {
            background-color: unset;
            margin: 0;
          }

          button.bp4-minimal.bp4-intent-primary.bp4-active {
            background: rgba(45, 114, 210, 0.2);
          }

          button.bp4-minimal.bp4-intent-primary.bp4-disabled span {
            color: #a7aeb8;
          }

          span {
            display: flex;

            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #515e75;
            text-align: left;
          }
        }

        &__badge {
          display: flex;
          align-items: center;
          width: 40px;

          .bp4-tag.bp4-round {
            min-height: 10px;
            min-width: 10px;

            color: #ffb54f;
            background-color: #ffefd9;

            font-size: 12px;
            font-weight: 700;
            line-height: 10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .wildfire-box-content-explorer-container {
      width: 100%;

      .box-content-explorer {
        width: 100%;
      }
      .wildfire-box-content-explorer-example-photo-container {
        display: none;
      }
    }
  }

  @media screen and (max-width: 900px) {
    margin-right: 0px;

    * {
      max-width: 100vw;
    }

    .wildfire-form-progress {
      display: none;
    }

    .wildfire-form-content .wildfire-form-content__header {
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 10px;
      button.bp4-button.bp4-minimal.bp4-intent-primary.wildfire-dashboard-navigation-button {
        margin: 0;
      }
    }

    .ucl-card,
    .ucl-card__body {
      padding: 0;
    }

    .bp4-card.wildfire-form-secondary-card > .bp4-collapse {
      padding-left: 0;
      padding-right: 0;

      .wildfire-form-container {
        margin-bottom: 60px;

        .wildfire-form-footer {
          padding: 12px 8px;
          margin-left: 0px;
        }
      }
    }
  }

  .ucl-card .service-options-display-container {
    .bp4-collapse,
    .ucl-card__body {
      padding: 0;
    }
  }

  .informational-card-container {
    margin: 0;

    .ucl-card {
      margin: 0 0 24px 0;
      padding: 8px 4px;
    }
  }

  .bp4-callout.wildfire-form-warning-callout {
    .bp4-heading {
      margin-bottom: 8px;
    }
  }
}

@media screen and (max-width: 600px) {
  .wildfire-form-content {
    overflow-y: hidden;

    .wildfire-form-content__header {
      justify-content: center;

      button.bp4-button.bp4-minimal.bp4-intent-primary.wildfire-dashboard-navigation-button {
        width: 100%;
      }
    }

    > .ucl-card
      .wildfire-form-primary-card
      .bp4-collapse
      .bp4-collapse-body
      .ucl-card__body {
      height: 70% !important;
    }
  }

  .wildfire-form-footer {
    button {
      min-width: 8rem;
    }
  }
}
