.grid-views-autocomplete{
  padding-right: 10px;
  .bp4-form-content {
    display: inline-flex;
    position: relative;
    left: 10px;
  }

  .async-autocomplete-toolbar {
    position: relative;
    top: 5px;
    // Search Input
    .async-autocomplete-input-container {
      position: relative;
      border: 1px solid #e0e0e07a !important;
  
      .e-input-group .e-input-group-icon:last-child {
        position: absolute !important;
        top: -1px !important;
        right: 11px !important;
      }
  
      .async-autocomplete-input,
      .e-input-group:not(.e-float-icon-left),
      .e-input-group.e-success:not(.e-float-icon-left),
      .e-input-group.e-warning:not(.e-float-icon-left),
      .e-input-group.e-error:not(.e-float-icon-left),
      .e-input-group.e-control-wrapper:not(.e-float-icon-left),
      .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
      .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
      .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left),
      //
      .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
      .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
      .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
      .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
      .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
      .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
      .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
      .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
      //
      .e-input-group input.e-input,
      .e-input-group textarea.e-input,
      .e-input-group input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
      .e-input-group textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]),
      .e-input-group.e-control-wrapper input.e-input,
      .e-input-group.e-control-wrapper textarea.e-input,
      .e-input-group.e-control-wrapper input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
      .e-input-group.e-control-wrapper textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]) {
        border: 1px solid #e0e0e07a !important;
        box-shadow: none;
        outline: none;
        border-radius: 4px;
        min-height: 30px;
        height: 30px;
        max-height: 30px;
        max-width: 200px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
  
      .async-autocomplete-input.e-control.e-autocomplete.e-lib.e-input.e-keyboard:read-only {
        background-color: #ffffff;
        color: #0d223f;
      }
      .async-autocomplete-input.e-control.e-autocomplete.e-lib.e-input.e-keyboard {
        background-color: #ffffff;
        color: #0d223f;
        border-color: #ffffff !important;
      }
  
      .e-input-group.e-control-wrapper.e-readonly.e-ddl,
      .e-input-group.e-control-wrapper.e-readonly.e-ddl.e-valid-input {
        cursor: not-allowed;
      }
  
      .e-spinner-pane.e-spin-show {
        top: -14px;
      }
  
      .e-input-group:not(.e-float-icon-left),
      .e-input-group.e-success:not(.e-float-icon-left),
      .e-input-group.e-warning:not(.e-float-icon-left),
      .e-input-group.e-error:not(.e-float-icon-left),
      .e-input-group.e-control-wrapper:not(.e-float-icon-left),
      .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
      .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
      .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
        border: 0 !important;
        border-width: 0px !important;
        height: 30px !important;
      }
  
      .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
      .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
      .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
      .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
      .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
      .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
      .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
      .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
        width: 0;
        display: none;
      }
  
      .e-float-input .e-clear-icon::before,
      .e-float-input.e-control-wrapper .e-clear-icon::before,
      .e-input-group .e-clear-icon::before,
      .e-input-group.e-control-wrapper .e-clear-icon::before {
        text-align: right;
        margin-right: 45px;
        margin-top: -30px;
        color: #ccc;
        display: none;
      }
  
      .e-input-focus {
        .async-autocomplete-input {
          border: 1px solid #afb7c4 !important;
        }
      }
  
      .async-autocomplete-input {
        padding: 3px 40px 3px 8px;
      }
  
      .bp4-icon-search {
        position: absolute;
        top: 8px;
        right: 18px;
        color: #e0e0e0;
      }
    }
  }
  
  .async-autocomplete-item-disabled-true {
    color: rgba(6, 34, 65, 0.5);
    opacity: 0.5;
    cursor: not-allowed;
  }
}