@import "../../../../variables";

.bp4-popover2 {
  box-shadow: none;

  .bp4-popover2-content {
    .bp4-input {
      border-radius: 100px;
    }

    .bp4-input-group {
      border-radius: 100px;

      .bp4-input-action {
        .bp4-icon.bp4-icon-caret-down.select-right-icon {
          color: #7f90a2;
        }

        .bp4-button.bp4-minimal {
          padding: 0px 0px 0px 0px;
          margin: 3px 10px 0px 0px;

          .bp4-icon.bp4-icon-cross {
            padding-top: 0px;
            padding-left: 0px;
            color: $red1;
          }
        }
      }
    }

    .bp4-menu {
      .bp4-menu-item.select__menu-item {
        padding: 5px 0px 5px 0px;
        text-align: center;

        .bp4-fill.bp4-text-overflow-ellipsis {
          font-size: 14px;
          padding: 0.1rem;
        }
      }

      li:not(:last-child) {
        border-bottom: solid 1px #e6ecf0;
      }
    }
  }
}

.label-tooltip-content {
  max-width: 24rem;
  padding: 0.5rem;
}
