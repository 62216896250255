@import "../../variables";

.ibhs-products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $mainBackground;
  max-height: 100dvh;
  overflow-y: auto;

  .ucl-card {
    width: 80%;
  }

  &__product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
}
