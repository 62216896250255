@import "../../../../variables";

.bids-page {
  display: flex;
  height: 100%;
  flex-direction: column;

  .bids-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    overflow: auto;

    .bids-container {
      display: flex;
      flex: 1 1 0;
      margin: 30px 80px 0px 80px;

      .ucl-card {
        background: none;

        .readonly-container.bp4-input.bp4-disabled {
          color: #373737;
          background-color: #f8fafc;
        }
      }

      .bids-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      .bid-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;

        .send-bid-request-button-container {
          .bp4-button {
            min-width: 12rem;
          }
        }
      }
    }
  }
}
