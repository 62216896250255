@import "../../../variables";

.comment-pannel {
  display: flex;
  flex-direction: column;
  padding: $mediumSpacing;
  padding-top: 0;
  min-width: 350px;
  width: $iterationEngineCommentWidth;
  overflow: hidden;

  width: $iterationEngineCommentWidth;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid #d9dde5;

  .message {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fdced2 !important;

    .e-msg-icon {
      color: #ff002b !important;
    }
  }
  .comment-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__no-selection {
      font-size: 16px;
      line-height: 20px;
      .bp4-icon {
        margin-right: 6px;
        margin-bottom: 2px;
        transform: rotate(90deg);
      }
    }

    .comment-list-view .change-request-navigator {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      height: 32px;
      min-height: 50px;
      border-radius: 6px;
      background-color: rgba(255, 189, 99, 0.2);
      color: $orange1;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;

      .bp4-icon svg {
        fill: $orange1;
      }

      .bp4-button {
        color: $orange1;
        font-size: 14px;
        line-height: 17px;
        padding-top: 7px;
      }

      .bp4-button:hover {
        color: darken($orange1, 10%);
        .bp4-icon svg {
          fill: darken($orange1, 10%);
        }
      }

      &_go-left {
        display: flex;
        background-color: transparent;

        .bp4-button {
          padding-left: 0;
          color: $orange1;
        }
      }

      .bp4-button:focus {
        outline-offset: -2px;
      }

      &_state {
        display: flex;
        margin: auto 0;
      }

      &_go-right {
        display: flex;

        .bp4-button {
          color: $orange1;
          padding-right: 0;
        }
      }

      &_place-holder {
        width: 30px;
        height: 32px;
      }
    }

    .comment-list-view {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin-top: 0px;

      &_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 4px;

        &_name {
          display: flex;
          color: #727e8e;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 20px;
        }
      }

      &_header_indicator {
        .bp4-tag {
          padding: 5px 16px;
          font-weight: 600;
        }

        // Resolved
        .bp4-tag.bp4-intent-success {
          background-color: $green3;
          color: $green1;
        }

        // Addressed
        .bp4-tag.bp4-intent-primary {
          background-color: $blue3;
          color: $mainBlueText2;
        }

        // Unresolved
        .bp4-tag.bp4-intent-warning {
          background-color: $orange2;
          color: $orange1;
        }

        // Unaddressed
        .bp4-tag.bp4-intent-danger {
          background-color: $tagRedBackground;
          color: $tagRedText;
        }

        .bp4-icon {
          margin-right: 5px;
        }
      }

      .change-request-comment {
        color: rgba(6, 34, 65, 0.5);

        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;

        &_old,
        &_new {
          color: #062241;
        }
      }

      &_item {
        margin-top: 8px;
        overflow: auto;
        display: flex;
        flex-direction: column;

        & > div {
          margin-bottom: 12px;
        }

        &_line-1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          white-space: initial;
          overflow-wrap: anywhere;

          &_name {
            display: flex;
            color: #062241;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            margin-right: 10px;

            &_icon {
              align-self: center;
              margin-right: 4px;
            }
          }

          &_time {
            display: flex;
            color: $mainGrayText4;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 17px;
          }

          &_delete {
            display: flex;
            margin-left: auto;

            .bp4-button {
              background-color: transparent;
            }

            .bp4-icon {
              color: scale-color($mainGrayText4, $lightness: 10%);
            }
          }
        }

        &_line-2 {
          display: flex;
          margin: 0;
          color: #062241;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
          overflow-wrap: anywhere;
        }
      }
    }

    .comment-input {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .comment-mode-selector {
        button.bp4-button {
          margin: 5px 0;
          padding: 4px 10px 5px 10px;
        }

        .comment-mode-selector-tooltip-container {
          width: 100%;
        }

        .comment-mode-selector-tooltip {
          padding: 0.5rem;
        }
      }

      &_actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 10px;

        .bp4-button.comment-input__submit-button {
          margin-right: 0;
          margin-top: 12px;
        }
      }

      &_resolve-button.bp4-button,
      &_unresolve-button.bp4-button {
        width: 100px;
        margin: 6px 5px 8px 0;
      }

      .comment-textarea {
        text-wrap: wrap;
        overflow-y: auto;
      }
    }
  }
}
