.wildfire-iteration-engine__right-panel {
  .bp4-tabs.fortified-iteration-engine-right-panel {
    padding-top: 5px;
    height: 95%;

    .auditor-checklist-panel {
      height: 95%;
      animation: slide-in 0.3s ease-in-out;
    }

    .bp4-tab-list {
      background-color: white;
      border-radius: 6px 6px 0 0;
      .bp4-tab {
        font-weight: 600;

        &:hover {
          background-color: rgba(0, 123, 255, 0.1);
          color: #007bff;
        }
      }

      .bp4-tab[aria-selected="true"] {
        outline: none;
        background-color: #f3f4f5 !important;
        box-shadow: 0px 4px 0px #007bff;
      }
    }

    .comment-pannel-v2 {
      padding-top: 1rem;
      .comment-container-v2 .comment-input {
        height: unset;
      }

      .comment-list-scroll-container {
        max-height: calc(43vh);
      }
    }
  }
}
