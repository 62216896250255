// Basic Theme System for Environments

.app-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  .tracking-container {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &.theme-tangerine {
    .navbar {
      background: #ffcc00;
      color: #000;
      border-bottom: 3px solid #dfb303;

      button {
        border: 1px solid #dfb303;
      }

      a {
        color: #000;
      }
    }
  }

  &.theme-sky {
    .navbar {
      background: #68a1bd;
      color: #fff;
      border-bottom: 3px solid #3a718d;

      button {
        border: 1px solid #3a718d;
      }

      a {
        color: #fff;
      }
    }
  }
}
