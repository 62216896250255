@import "../../../variables";

.relativity-card-header {
  border-bottom: $relativitySubCardBottomBorder;
  margin-bottom: 15px;

  .bp4-heading {
    color: $mainBlackText;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
}

.relativity-subcard-header {
  .bp4-heading {
    color: $mainGray6;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
  }
}
