.bp4-button.bp4-minimal.bp4-intent-primary.create-wildfire-application-button-small {
  width: 180px;
  margin: 8px 0 24px 4px;
  background-color: #d4e3ff;

  @media screen and (max-width: 900px) {
    width: inherit;
    margin: 8px;
  }
}
