@import "../../../../variables";

.evaluation-steps-container {
  background-color: white;
  min-width: 17rem;
  border-radius: 3%;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #e6ecf0;

  &__header {
    padding: 15px;
    font-weight: bold;
    border-bottom: 1px solid #e6ecf0;
  }

  &__steps {
    min-width: 13rem;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .evaluation-steps-container-step {
      padding: 15px 20px 10px 20px;
      display: flex;
      align-items: center;

      &__label {
        margin-left: 10px;
      }
    }

    .bp4-icon-tick-circle {
      color: $green1;
    }

    .bp4-icon-cross-circle {
      color: $red1;
    }

    .bp4-icon-circle {
      color: #ffbd63;
    }

    .bp4-icon-full-circle {
      color: $mainGray7;
    }
  }
}

.evaluation-form-navigation-container {
  background-color: white;
  border-radius: 3%;

  &__header {
    padding: 15px;
    font-weight: bold;
    border-bottom: 1px solid #e6ecf0;
  }

  .evaluation-form-navigation__tabs {
    display: flex;
    flex-direction: column;

    .evaluation-form-navigation-tab {
      display: flex;
      justify-content: space-between;

      .non-compliant-count {
        display: flex;
        align-items: center;

        .bp4-tag.bp4-round {
          min-height: 10px;
          min-width: 10px;

          color: white;
          background-color: #ff9595;

          font-size: 12px;
          font-weight: 700;
          line-height: 10px;
        }
      }
    }
  }
}
