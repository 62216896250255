@import "../../variables";

.fortified-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 64px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
  }
}

.board-page {
  .page-main-content {
    padding: 1rem;
    width: calc(100% - 60px);
    height: calc(100vh - 60px);
    overflow-y: auto; // needed for Board.tsx card view to scroll.

    .admin-tabs {
      .bp4-tab[aria-selected="true"],
      .bp4-tab:not([aria-disabled="true"]):hover {
        color: $sidebarBlue;
        box-shadow: inset 0 -3px 0 $sidebarBlue !important;
      }
    }

    section {
      display: flex;
      flex-flow: row;

      &.board-toolbar {
        margin-bottom: 15px;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          width: 100%;
          display: flex;
          flex-direction: row;
        }

        ul.board-toolbar-left {
          justify-content: flex-start;
          margin-left: -10px;
        }
        ul.board-toolbar-right {
          justify-content: flex-end;
          margin-right: -10px;
        }

        li.board-toolbar-right--card-view-toggle {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 10px;
          margin-right: 12px;
        }

        button.bp4-button.bp4-button {
          font-size: 14px;
        }
      }

      &.board-grid-container,
      .fortified-grid-container {
        display: block;

        &.has-tabs {
          .e-content {
            max-height: calc(100vh - 18.7rem);
          }
        }
      }
    }
  }

  .page-main-content:has(> .board-metrics-container
      .grid-metrics.grid-metrics-empty) {
    height: calc(100vh - 24px);
  }

  button.bp4-button.bp4-button.evaluation-button {
    width: 152px;
    height: 34px;
  }

  button.bp4-button.bp4-button.evaluation-button-wide-fcfmf {
    width: 200px;
  }

  button.bp4-button.bp4-button.evaluation-button-wide-fh {
    width: 162px;
  }
}

.reports-page {
  height: 100%;
  overflow-y: scroll;

  &__reports {
    display: flex;
    flex-wrap: wrap;

    &__report-item {
      position: relative;
      margin: 1em;

      img {
        border-radius: 0.5em;
      }

      &__overlay-container {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-align: center;
      }
    }
  }
}

.bp4-tab-panel {
  height: 100%;
}

.help-tabs {
  .bp4-tab[aria-selected="true"],
  .bp4-tab:not([aria-disabled="true"]):hover {
    color: $sidebarBlue;
    box-shadow: inset 0 -3px 0 $sidebarBlue !important;
  }
  height: 100%;
}

.help-page {
  display: flex;
  height: 100%;

  &__sidebar {
    height: 100%;
  }

  &__content {
    width: calc(100% - 200px);
    height: 100%;
    width: 100%;

    &__iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

.create-fortified-application-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;

  button.bp4-button.bp4-minimal.create-fortified-application-button {
    border: 2px dashed lightblue;
    width: 80%;
    height: 20rem;
    background-color: #dfe3fe;
  }

  button.bp4-button.bp4-button.bp4-minimal .bp4-icon {
    padding-top: 0px;
  }
}
