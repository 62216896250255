@import "../../../../variables";

.field-label {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  color: $mainBlackText;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;

  .label-title {
    padding-right: 0.5rem;
  }

  .bp4-popover2-target {
    margin-top: 0 !important;
  }
}

.bp4-icon {
  flex: 0;
  color: #727e8e;
}

.bp4-form-group {
  margin: 0 0 5px;
}

.bp4-popover2-wrapper {
  .bp4-popover2-target {
    width: 100%;
  }
}
