.auditor-checklist-panel {
  padding: 15px;
}

.auditor-checklist-item {
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px 10px 0 10px;
  margin-bottom: 10px;
  font-weight: 500;

  hr {
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    border-left: unset;
  }

  &.pass {
    border: 2px solid green;
    background-color: rgba(0, 128, 0, 0.4);
    color: green;
  }

  &.fail {
    border: 2px solid red;
    background-color: rgba(255, 0, 0, 0.4);
    color: red;
  }

  &.na {
    border: 2px solid gray;
    background-color: rgba(128, 128, 128, 0.4);
    color: gray;
  }

  .auditor-checklist-item-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    span:first-child {
      padding-right: 5px;
    }
  }
}
