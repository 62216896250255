@import "../../../variables";

@keyframes enter {
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0);
  }
}

.fortified-grid-filters-module {
  width: 164px;
  min-width: 164px;

  .fortified-grid-filters-count {
    width: 164px;
    min-width: 164px;
    /* duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
    animation: 300ms ease-out 0s 1 enter;

    .fortified-grid-filters-count-icon {
      width: 16px;
      min-width: 16px;
    }
    .fortified-grid-filters-count-text {
      width: 65px;
      min-width: 65px;
    }
    .fortified-grid-filters-count-clear {
      width: 16px;
      min-width: 16px;
    }

    button.bp4-button.bp4-button.fortified-grid-filters-count-target {
      border-radius: 24px;
      padding: 10px 15px;
      font-size: 14px;
      background: $orange2;
      box-shadow: $pillBoxShadow;
      width: 140px;
      max-width: 140px;
      min-width: 140px;

      &:hover {
        box-shadow: $pillBoxShadowHover;
      }
      .bp4-button-text {
        display: grid;
        gap: 10px;
        grid-auto-flow: column;
        height: 13px;
        line-height: 13px;
        text-align: center;

        .bp4-icon {
          color: $orange3;
        }
      }
    }
  }

  .bp4-popover2.bp4-popover2-content-sizing .bp4-popover2-content {
    min-width: 300px;
    max-width: 300px;
    padding: 10px;

    .fortified-grid-filters-details {
      .fortified-grid-filters-details-list {
        list-style: none;
        padding: 0;
        margin: 0;

        > li {
          width: 100%;
          padding: 5px 10px;
          .bp4-tag {
            width: 100%;
            padding: 5px 10px;
            background-color: $mainGrayText4;

            .bp4-icon {
              margin: 2px 5px;
            }
          }
        }
      }
      .fortified-grid-filters-details-clear {
        margin: 0;
        background: none;
        font-size: 13px;
        color: $mainGrayText4;
        width: 100%;
        display: block;
        text-align: right;
      }
    }
  }
}
