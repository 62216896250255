@import "../variables";

.base-field {
  .bp4-input {
    @include inputBase;
  }

  .bp4-input-group.bp4-disabled {
    .bp4-input:disabled {
      color: $mainGrayText2;
    }
  }
}

.radio-field {
  .bp4-control {
    input:checked ~ .bp4-control-indicator {
      background-color: $mainGray6;

      &::before {
        background-image: radial-gradient(
          #ffffff,
          #ffffff 36%,
          transparent 40%
        );
      }
    }
  }
  .bp4-disabled {
    input:checked ~ .bp4-control-indicator {
      opacity: 0.5;
    }
  }
}

.checkbox-field {
  input:checked ~ .bp4-control-indicator {
    background-color: $mainGray6;
  }

  input:checked ~ .bp4-control-indicator:hover,
  .bp4-control:hover input:checked ~ .bp4-control-indicator {
    background-color: lighten($mainGray6, 10%);
  }
  input:disabled:checked ~ .bp4-control-indicator {
    background-color: $mainGray6;
    opacity: 0.5;
  }
}

.select-field {
  .bp4-tag {
    background-color: $blue3;
    color: $mainBlueText2;
  }

  .bp4-input.bp4-disabled {
    background-color: transparent;
    padding-left: 0;
  }
}

.select__menu-item.bp4-active.bp4-intent-primary {
  background-color: $blue3;
  color: $mainBlackText;
}

.numeric-input-field {
  .bp4-numeric-input {
    max-width: 250px;
    margin-top: 8px;

    .bp4-input-group {
      width: 100%;
      min-width: 10rem;
      .bp4-input {
        width: 100%;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    .bp4-button-group.bp4-vertical {
      .bp4-button {
        margin-left: 1px;
      }

      .bp4-button.bp4-disabled {
        display: none;
      }
    }
  }
}
