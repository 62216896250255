.wildfire-box-content-explorer-field {
  .has-required-background {
    .box-content-explorer {
      background-color: #ffefd9;
      box-shadow: none;
      border-radius: 6px;
      border: 1px solid #ffcc00;
      padding: 5px;
    }
  }
}

.wildfire-box-content-explorer-has-example-photo {
  flex-wrap: wrap;

  .box-content-explorer {
    width: 60%;
  }
}

.wildfire-box-content-explorer-no-example-photo {
  .box-content-explorer {
    width: 100%;
  }
}

.wildfire-box-content-explorer-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 401.6px;
  align-items: center;

  .box-content-explorer {
    box-shadow: 0px 0px 20px 0px #0000000f;
    border: 1px solid #e8eef4;
    height: 100%;

    .be {
      margin-top: 0;
    }

    .btn.be-btn-add {
      background-color: #d4e3ff;
      color: #4d85eb;
      .btn-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1rem;
        justify-content: space-between;

        .icon-add-thin {
          display: none;
        }
        .svg-inline--fa.fa-plus {
          padding-right: 5px;
        }
      }
    }

    .btn.be-btn-annotate {
      background-color: #4d85eb; /* Primary button color */
      color: #ffffff; /* White text for better contrast */
      padding: 8px 12px; /* Adjust padding for better spacing */
      margin-left: 7px;
      border: none;
      border-radius: 6px; /* Rounded corners */
      cursor: pointer;
      font-weight: bold;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
      transition: background-color 0.3s ease, box-shadow 0.3s ease,
        transform 0.1s ease;

      .btn-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 1rem;
        justify-content: space-between;
      }

      .svg-inline--fa.fa-pencil {
        padding-right: 8px; /* Better spacing for the icon */
      }

      /* Hover and active effects */
      &:hover {
        background-color: #3a6fcc; /* Slightly darker on hover */
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Enhance shadow on hover */
      }

      &:active {
        transform: scale(0.98); /* Slightly shrink on click */
      }
    }

    .bdl-GridViewSlot {
      padding: 15px;

      .be-item-name {
        button {
          text-wrap: wrap;
        }
      }
    }
  }

  .wildfire-box-content-explorer-example-photo-container {
    width: 30%;
    margin-left: 12px;

    .wildfire-box-content-explorer-example-photo {
      max-width: 260px;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;

      box-shadow: 0px 0px 20px 0px #0000000f;
      border: 1px solid #e8eef4;
    }
  }
}
