.fortified-home-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ucl-app-header {
    .ucl-app-header-breadcrump {
      display: none !important;
    }

    .user-profile-tab {
      min-width: 20rem;
      justify-content: flex-end;
    }
  }
  .content {
    display: flex;
    height: 100%;

    &:has(.fortified-home-form-page) {
      overflow: hidden;
    }

    .fortified-home-page-main-content {
      width: 100%;
      padding: 15px;
      overflow: auto;

      > div {
        height: 100%;
      }

      .primary-grid .ucl-grid-views-single-select .bp4-label.ucl-field-label {
        margin-top: 0;
      }
    }

    @media screen and (max-width: 1000px) {
      .fortified-home-page-main-content {
        padding: 0;
      }
      .ucl-grid-container .e-grid .e-toolbar:has(.e-toolbar-items) {
        margin: 0;
      }
      .ucl-grid-container .e-grid .e-toolbar:has(.e-toolbar-items) {
        padding-bottom: 0;
      }
      .e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
        position: relative;
        width: 100%;
      }
      .ucl-grid-container
        .e-grid
        .e-toolbar-items
        .e-toolbar-item.e-search-wrapper
        .e-search,
      .e-toolbar
        .e-toolbar-items
        .e-toolbar-item:not(.e-separator).e-search-wrapper {
        width: 100%;
      }
      .e-grid
        .e-toolbar-items
        .e-toolbar-item.e-search-wrapper
        .e-search
        .e-search-icon {
        display: none;
      }

      .ucl-grid-container
        .e-grid
        .e-toolbar-items
        .e-toolbar-item.e-search-wrapper
        .e-search {
        border: 0 !important;
        border-radius: 0 !important;
        background: #fff !important;
      }
      .e-toolbar .e-toolbar-items .e-toolbar-left,
      .e-toolbar .e-toolbar-items .e-toolbar-center,
      .e-toolbar .e-toolbar-items .e-toolbar-right {
        display: block;
      }
      .e-hscroll .e-scroll-nav.e-scroll-left-nav,
      .e-hscroll .e-scroll-nav.e-scroll-right-nav {
        display: none;
      }
      .e-hscroll:not(.e-scroll-device),
      .e-hscroll.e-scroll-device {
        padding: 0 !important;
      }
      .e-hscroll .e-hscroll-content {
        display: block;
      }

      .fortified-home-board {
        padding-right: 0;
      }
    }

    .sidebar-treeview {
      .ucl-navigationtree-input-list {
        margin-left: 0px !important;
      }

      .e-text-content {
        padding: 10px 0px 0px 0px !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .fortified-home-page .ucl-app-header {
    .user-profile-tab {
      min-width: 0;

      &__user-name {
        display: none;
      }

      &__user-role {
        display: none;
      }
    }
  }
}
