@import "../../../variables";

.checklist-container {
  height: 100%;
  padding: $mediumSpacing;
  overflow: hidden;
  width: 100%;

  .bp4-tab-list {
    margin-top: 36px;
  }

  .bp4-tabs {
    height: 100%;

    .bp4-tab-panel {
      width: 100%;
    }
  }
}

.form-tab {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  height: 92.5%;

  &__header {
    border-bottom: 1px solid #e6ecf0;
  }

  &__icon {
    margin-right: 7.5px;
    color: $mainBlueText1;
    cursor: pointer;
  }

  &__notification-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 36px;
    margin-bottom: 16px;

    &__right-pannel {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__left-pannel {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .email-notification {
      margin: 0;
    }
  }

  a {
    color: $mainBlueText1;
  }

  .bp4-card {
    margin: 1px;
    overflow-y: scroll;

    table {
      color: #727e8e;
      width: 100%;
      height: 100%;
      font-size: 16px;
      border-collapse: collapse;

      tr:not(:last-child) {
        border-bottom: 1px solid #e6ecf0;
      }

      td {
        padding: 10px;
        min-width: 200px;
      }

      td.submitter {
        color: $mainBlackText;
      }

      .form-tab__status {
        text-align: center;
      }
    }
  }
}
